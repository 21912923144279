import { getApiUrl } from './helpers'
import ApiImage from '../../ApiImage'
import { Preset } from '../../../constants/asset'
import { useApi } from '../../../data/api'

import './Image.css'

/**
 * Markdown image component
 * ```md
 * ![alt](src "title")
 * ```
 * @link https://docs.directus.io/reference/files.html
 */
const ImageComponent: preact.FunctionComponent<{
  src: string,
  alt: string,
  children: undefined,
  title?: string,
}> = ({
  src,
  alt = '',
  title = undefined,
}) => {
  const { baseUrl } = useApi()

  const apiUrl = getApiUrl(src, baseUrl)

  // External image
  if (!apiUrl) {
    return (
      <img
        alt={alt}
        src={src}
        title={title}
      />
    )
  }

  // Resolve Asset ID
  const id = apiUrl.pathname.replace(/^\/assets\/([^\/]+).*/, '$1')

  return (
    <ApiImage
      asset={{
        id,
        width: undefined,
        height: undefined,
        type: undefined,
        title: alt,
        source: null,
      }}
      preset={Preset.ContentImage}
      wrapperClassName="m1-api-image--md-content"
    />
  )
}

export default ImageComponent
