const routes = {
  start:                  '/',
  citiesList:             '/cities',
  // Organisation context Shared URLs
  city:                   '/city/:organisationSlug',
  aboutCity:              '/city/:organisationSlug/about',
  newsList:               '/city/:organisationSlug/news',
  newsItem:               '/city/:organisationSlug/news/:newsItemId',
  airQualityStationsList: '/city/:organisationSlug/air-quality',
  garbageCollections:     '/city/:organisationSlug/garbage-collection',
  cleanAir:               '/city/:organisationSlug/clean-air',
} as const

export default routes

/**
 * Array of Route Config Objects
 * @link https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
 * @link https://www.npmjs.com/package/react-router-config#route-configuration-shape
 */
export const routeConfig = Object.values(routes).map(path => ({ path }))
