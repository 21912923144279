import clsx from 'clsx'

import './Tags.css'

/**
 * Button tags component
 */
const ButtonTags: preact.FunctionComponent<{
  /** Available items */
  items: string[] | null,
  /** Selected items */
  selected: string[],
  /** Click handler */
  onClick?: (event: MouseEvent) => void,
}> = ({
  items,
  selected,
  onClick,
}) =>
  <ul className="m1-tags-list ion-margin">
    {items?.map(item =>
      <li
        key={item}
        className={`m1-tag m1-tag--${item}`}
      >
        <button
          className={clsx('m1-tag-button', { 'm1-tag-button--active': selected.includes(item)})}
          type="button"
          value={item}
          onClick={onClick}
        >
          {item}
        </button>
      </li>
    )}
  </ul>

export default ButtonTags
