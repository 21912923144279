import { useLocation, useRouteMatch } from 'react-router'
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from '@ionic/react'

import type { OrganisationPageProps } from '../../contexts/organisation'

import { routes, generatePath } from '../../navigation'

import Page from '../../components/Page'

import './NotFoundPage.css'

/**
 * Not found page
 */
const NotFoundPage: preact.FunctionComponent = () => {
  const { params: { organisationSlug } } = useRouteMatch<Partial<OrganisationPageProps>>()

  const location = useLocation()

  // Note: During redirection fallbackRoute may match location.pathname
  const fallbackRoute = organisationSlug
    ? generatePath(routes.city, { organisationSlug })
    // Do not redirect to start page, which may in turn redirect to city page where useRouteMatch fails to populate params
    : generatePath(routes.citiesList)

  // Trim slash from beginning
  const path = location.pathname.replace(/^\/(.*)/, '$1')

  return (
    <Page screenName="NotFound">
      <div className="m1-page__error-wrapper">
        <IonCard className="m1-card">
          <IonCardHeader>
            <IonCardTitle>
              {'Nie znalezionno strony'}
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <code className="m1-page-error__url">
              {path}
            </code>
          </IonCardContent>
          <IonButton
            color="primary"
            fill="solid"
            routerLink={fallbackRoute}
            routerDirection="none"
          >
            {'Przejdź na stronę główną'}
          </IonButton>
        </IonCard>
      </div>
    </Page>
  )
}

export default NotFoundPage
