import { ConnectError } from '../../utils/Error'

declare type ApiFetcher = (path: string, requestInit?: RequestInit) => Promise<any>

/**
 * Syngeos API Fetcher factory
 */
export default function createFetcher(baseUrl: string): ApiFetcher {
  return async (path: string, ...restKeyArguments: any[]) => {
    const url = `${baseUrl}/${path}`
    const request = new Request(url, undefined)

    let response: Response

    try {
      response = await fetch(request)
    } catch (typeError) {
      throw new ConnectError(request, { cause: typeError as TypeError })
    }

    if (!response.ok) {
      throw new ConnectError(request)
    }

    return response.json()
  }
}
