import { useEffect, useMemo, useRef, useState } from 'preact/hooks'
import { generatePath, useRouteMatch } from 'react-router'
import { IonAlert, useIonRouter, type AlertButton } from '@ionic/react'
import type { KeyedMutator } from 'swr'

import type { OrganisationPageProps } from '../../contexts/organisation'

import { ApiError, ConnectError, TransferError } from '../../utils/Error'
import { routes } from '../../navigation'

import './TransferErrorAlert.css'

/**
 * Handle Connect/ API error
 */
const TransferErrorAlert: preact.FunctionComponent<{
  error: TransferError,
  mutate: KeyedMutator<any>,
}> = ({
  error,
  mutate,
}) => {
  const { params: { organisationSlug } } = useRouteMatch<Partial<OrganisationPageProps>>()

  const isMountedRef = useRef<boolean>(true)
  const [ isRetry, setIsRetry ] = useState<boolean>(false)

  const { push } = useIonRouter()

  const ionAlertRef = useRef<HTMLIonAlertElement>(null)

  useEffect(() => () => { isMountedRef.current = false })

  /**
   * Handle cancel button click
   */
  const handleCancelButtonClick = () => {
    push(generatePath(routes.city, { organisationSlug: organisationSlug! }), 'root', undefined)
  }

  /**
   * Handle retry button click
   */
  const handleRetryButtonClick = (): boolean => {
    if (!isRetry) {
      setIsRetry(true)

      mutate()
        .finally(() => isMountedRef.current && setIsRetry(false))
    }

    return false
  }

  // Error info
  const ionAlertProps = useMemo(
    () => getIonAlertProps(error),
    [error]
  )

  // Modal buttons
  const cancelButton: AlertButton = {
    text: 'Anuluj',
    handler: handleCancelButtonClick,
  }

  const retryButton: AlertButton = {
    text: 'Ponów',
    cssClass: isRetry ? 'm1-alert-button-disabled' : undefined,
    handler: handleRetryButtonClick,
  }

  const buttons: AlertButton[] = useMemo(
    () => {
      const buttons = []

      // Cancel -> Redirect to organisation list
      // TODO: Make sure we are not on city page
      if (organisationSlug) {
        buttons.push(cancelButton)
      }

      // Connect error
      if (error instanceof ConnectError) {
        buttons.push(retryButton)
      }

      return buttons
    },
    [organisationSlug, error]
  )

  // Trick to update memoized ionic alert button cssClass
  useEffect(() => {
    if (ionAlertRef.current) {
      ionAlertRef.current.buttons = buttons
    }
  }, [isRetry])


  return (
    <IonAlert
      ref={ionAlertRef}
      header={ionAlertProps.header}
      subHeader={ionAlertProps.subHeader}
      message={ionAlertProps.message}
      buttons={buttons}
      backdropDismiss={false}
      keyboardClose={false}
      isOpen={true}
    />
  )
}

export default TransferErrorAlert

/**
 * Get info for transport error, compatible with IonAlert comonent
 */
function getIonAlertProps(error: TransferError): {
  header: string,
  subHeader?: string,
  message?: string
} {
  if (error instanceof ConnectError) {
    return {
      header: 'Brak dostępu do sieci',
      message: 'Upewnij się że twoje urządzenie ma aktywny dostęp do sieci internet.',
    }
  }

  if (error instanceof ApiError) {
    switch (error.response.status) {
      case 404: return { header: 'Nie znaleziono pozycji' }
      default: return { header: 'Błąd serwera' }
    }
  }

  // Never
  return { header: 'Coś poszło nie tak' }
}
