import {
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonRadio,
  IonRadioGroup,
} from '@ionic/react'

import type { TGarbageZone } from '../../models'

/**
 * Garbage zone picker comopnent
 */
const GarbageZonePicker: preact.FunctionComponent<{
  selected: number | null,
  garbageZones: TGarbageZone[]
  setGarbageZone: (id: number) => void,
}> = ({
  selected,
  garbageZones,
  setGarbageZone,
}) => {
  return (
    <IonList>
      <IonRadioGroup
        value={selected}
        onIonChange={e => setGarbageZone(e.detail.value)}
      >
        {garbageZones.map((garbageZone, index) =>
          <IonItem
            key={garbageZone.id}
            lines={index < garbageZones.length - 1 ? undefined : 'none'}
          >
            <IonLabel className="ion-text-wrap">
              <div>
                {garbageZone.name}
              </div>
              {garbageZone.description &&
                <IonNote>
                  {garbageZone.description}
                </IonNote>
              }
            </IonLabel>
            <IonRadio
              slot="start"
              value={garbageZone.id}
            />
          </IonItem>
        )}
      </IonRadioGroup>
    </IonList>
  )
}

export default GarbageZonePicker
