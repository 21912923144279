import { useRouteMatch } from 'react-router'
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonRow,
} from '@ionic/react'

import type { OrganisationPageProps } from '../../contexts/organisation'

import { routes, generatePath } from '../../navigation'

import Page from '../../components/Page'

import { ReactComponent as GarbageCollectionsIcon } from '../../images/pages/garbage-collections.svg'
import { ReactComponent as AirQualityIcon } from '../../images/pages/air-quality.svg'
import { ReactComponent as NewsIcon } from '../../images/pages/news.svg'
import { ReactComponent as CleanAirIcon } from '../../images/pages/clean-air.svg'
import { ReactComponent as AboutCityIcon } from '../../images/pages/about-city.svg'

import './CityPage.css'

/**
 * City page (non-customized)
 */
const CityPage: preact.FunctionComponent = () => {
  const { params: { organisationSlug } } = useRouteMatch<OrganisationPageProps>()

  return (
    <Page screenName="City">
      <IonGrid className="m1-dashboard-grid">
        <IonRow className="ion-align-items-stretch">
          <IonCol size="6">
            {/** News */}
            <IonCard
              color={undefined}
              routerLink={generatePath(routes.newsList, { organisationSlug })}
              routerDirection="none"
              className="m1-dashboard-card"
            >
              <IonCardContent className="m1-dashboard-card-content ion-no-padding">
                <NewsIcon
                  className="m1-dashboard-card-icon m1-dashboard-card-icon--news"
                  title={'Aktualności'}
                />
                <IonCardHeader className="m1-dashboard-card-header">
                  <IonCardTitle className="m1-dashboard-card-title">
                    {'Aktualności'}
                  </IonCardTitle>
                </IonCardHeader>
              </IonCardContent>
            </IonCard>
          </IonCol>

          <IonCol size="6">
            {/** Garbage collections */}
            <IonCard
              color={undefined}
              routerLink={generatePath(routes.garbageCollections, { organisationSlug })}
              routerDirection="none"
              className="m1-dashboard-card"
            >
              <IonCardContent className="m1-dashboard-card-content ion-no-padding">
                <GarbageCollectionsIcon
                  className="m1-dashboard-card-icon m1-dashboard-card-icon--garbage-collections"
                  title={'Harmonogram orbioru śmieci'}
                />
                <IonCardHeader className="m1-dashboard-card-header">
                  <IonCardTitle className="m1-dashboard-card-title">
                    {'Harmonogram odbioru śmieci'}
                  </IonCardTitle>
                </IonCardHeader>
              </IonCardContent>
            </IonCard>
          </IonCol>

          <IonCol size="6">
            {/** Air quality */}
            <IonCard
              color={undefined}
              routerLink={generatePath(routes.airQualityStationsList, { organisationSlug })}
              routerDirection="none"
              className="m1-dashboard-card"
            >
              <IonCardContent className="m1-dashboard-card-content ion-no-padding">
                <AirQualityIcon
                  className="m1-dashboard-card-icon m1-dashboard-card-icon--air-quality"
                  title={'Jakość powietrza'}
                />
                <IonCardHeader className="m1-dashboard-card-header">
                  <IonCardTitle className="m1-dashboard-card-title">
                    {'Jakość powietrza'}
                  </IonCardTitle>
                </IonCardHeader>
              </IonCardContent>
            </IonCard>
          </IonCol>

          <IonCol size="6">
            {/** Clean Air */}
            <IonCard
              color={undefined}
              routerLink={generatePath(routes.cleanAir, { organisationSlug })}
              routerDirection="none"
              className="m1-dashboard-card"
            >
              <IonCardContent className="m1-dashboard-card-content ion-no-padding">
                <CleanAirIcon
                  className="m1-dashboard-card-icon m1-dashboard-card-icon--clean-air"
                  title={'Czyste Powietrze'}
                />
                <IonCardHeader className="m1-dashboard-card-header">
                  <IonCardTitle className="m1-dashboard-card-title">
                    {'Czyste Powietrze'}
                  </IonCardTitle>
                </IonCardHeader>
              </IonCardContent>
            </IonCard>
          </IonCol>

          <IonCol size="6">
            {/** About City */}
            <IonCard
              routerLink={generatePath(routes.aboutCity, { organisationSlug })}
              routerDirection="none"
              className="m1-dashboard-card"
            >
              <IonCardContent className="m1-dashboard-card-content ion-no-padding">
                <AboutCityIcon
                  className="m1-dashboard-card-icon m1-dashboard-card-icon--about-city"
                  title={'O mieście'}
                />
                <IonCardHeader className="m1-dashboard-card-header">
                  <IonCardTitle className="m1-dashboard-card-title">
                    {'O mieście'}
                  </IonCardTitle>
                </IonCardHeader>
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>
    </Page>
  )
}

export default CityPage
