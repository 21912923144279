import type { IGlobalQueryParams } from './types'
import type { TImage } from '../../models/api'
import type { Preset } from '../../constants/asset'
import { ApiError, ConnectError } from '../../utils/Error'

/**
 * Directus fetchers factory
 */
export default function createFetchers (baseUrl: string, staticToken: string) {
  return {
    // Directus API fetcher
    swr: ({ path, query }: { path: string, query?: IGlobalQueryParams }): Promise<unknown> =>
      xfetch(path, createParams(query), 'application/json')
        .then(response => response.json())
    ,

    // Directus Asset fetcher
    asset: (asset: TImage, preset?: Preset): Promise<Blob> =>
      xfetch(`assets/${asset.id}`, createAssetParams(preset), 'image/*')
        .then(response => response.blob())
    ,
  }

  // Custom Directus API fetch
  async function xfetch (path: string, params: URLSearchParams, accept: string = '*/*'): Promise<Response> {
    const url = new URL(`${baseUrl}/${path}?${params}`)
    const headers = new Headers({
      accept,
      authorization: `Bearer ${staticToken}`,
    })

    const request = new Request(url, {
      method: 'GET',
      headers,
      mode: 'cors',
    })

    let response: Response

    // Detect connection error
    try {
      response = await fetch(request)
    } catch (typeError) {
      throw new ConnectError(request, { cause: typeError as TypeError })
    }

    // Detect API error
    if (!response.ok) {
      throw new ApiError(request, response)
    }

    return response
  }
}

/**
 * Directus REST API query params builder
 * @link https://docs.directus.io/reference/query/
 */
function createParams({
  fields = ['*'],
  filter,
  search,
  sort,
  limit = -1,
  offset,
  page,
  aggregate,
  groupBy,
  deep,
  alias,
  meta,
}: IGlobalQueryParams = {}): URLSearchParams {
  const searchParams = new URLSearchParams()

  // Select fields
  if (fields?.length) {
    searchParams.set('fields', fields.join(','))
  }

  // Filter
  if (filter) {
    searchParams.set('filter', JSON.stringify(filter))
  }

  // Search
  if (search) {
    searchParams.set('search', search)
  }

  // Sort
  if (sort?.length) {
    searchParams.set('sort', sort.join(','))
  }

  // Limit
  if (limit !== undefined) {
    searchParams.set('limit', limit.toFixed(0))
  }

  // Offset
  if (offset !== undefined) {
    searchParams.set('offset', offset.toFixed(0))
  }

  // Page
  if (page !== undefined) {
    searchParams.set('page', page.toFixed(0))
  }

  // Aggregate (TODO: Check if passing object works)
  if (aggregate) {
    for (const [fn, column] of Object.entries(aggregate)) {
      searchParams.set(`aggregate[${fn}]`, column)
    }
  }

  // Group By
  if (groupBy?.length) {
    searchParams.set('groupBy', groupBy.join(','))
  }

  // Deep
  if (deep) {
    searchParams.set('deep', JSON.stringify(deep))
  }

  // Alias
  if (alias) {
    searchParams.set('deep', JSON.stringify(alias))
  }

  // Meta
  if (meta) {
    searchParams.set('meta', meta)
  }

  return searchParams
}

/**
 * Directus REST API asset params
 * @link https://docs.directus.io/reference/files.html
 */
function createAssetParams(preset?: Preset): URLSearchParams {
  const searchParams = new URLSearchParams()

  // Preset
  if (preset) {
    searchParams.set('key', preset)
  }

  return searchParams
}
