import type { SWRConfiguration, KeyedMutator } from 'swr'
import useSWR from 'swr'

import type {
  IGlobalQueryParams,
  OneItem,
} from './types'
import { useApi } from './ApiContext'
import { TransferError } from '../../utils/Error'

const fallbackData = null

/**
 * Generic item
 * @link https://docs.directus.io/reference/items/
 */
export default function useItem<Schema extends object> (
  resource: string,
  query: IGlobalQueryParams,
  shouldFetch: boolean = true,
  customConfig?: SWRConfiguration<OneItem<Schema>, TransferError>
): {
  data: Schema | null,
  error: TransferError | undefined,
  isLoading: boolean,
  mutate: KeyedMutator<OneItem<Schema>>,
} {
  const path = `items/${resource}`
  const { config } = useApi()

  const {
    data,
    error,
    mutate,
  } = useSWR<OneItem<Schema>, TransferError>(shouldFetch ? { path, query } : null, {
    fallbackData: undefined,
    ...config,
    ...customConfig,
  })

  return {
    data: data ? data.data : fallbackData,
    error: shouldFetch && !data ? error : undefined,
    isLoading: shouldFetch && !data && !error,
    mutate,
  }
}
