import type { StorageHandler } from '@piotr-cz/swr-idb-cache'
import { timestampStorageHandler } from '@piotr-cz/swr-idb-cache'

type Data = any
type StoreObject = {
  value: Data,
  ts: number,
}

export default class ApiStorageHandler implements StorageHandler<Data, StoreObject> {
  /** GC max age (should assets gc) */
  protected maxAge = 7 * 24 * 60 * 60 * 1e3

  /** Paths to always persist (ignore from GC) */
  protected alwaysPersistPaths = [
    'items/organisations',
    'fields/news/tags',
  ]

  /**
   * @inheritdoc
   */
  initialize = timestampStorageHandler.initialize

  /**
   * @inheritdoc
   */
  upgrade = timestampStorageHandler.upgrade

  /**
   * @inheritdoc
   */
  replace = timestampStorageHandler.replace

  /**
   * @inheritdoc
   */
  revive (key: string, storeObject: StoreObject): Data | undefined {
    return this.shouldRevive(key, storeObject)
      ? timestampStorageHandler.revive(key, storeObject)
      : undefined
  }

  /**
   * As there is no support for unserializing key/ stableHash, so hooked up on _k
   * @link https://github.com/vercel/swr/blob/main/_internal/utils/hash.ts
   * @note May decide depening on wheter organisation provided in query filter matches current one
   */
  protected shouldRevive(key: string, storeObject: StoreObject): boolean {
    const { path } = storeObject.value._k

    // Any organisation
    if (this.alwaysPersistPaths.includes(path)) {
      return true
    }

    // Scoped, within max age
    if (storeObject.ts > Date.now() - this.maxAge) {
      return true
    }

    return false
  }
}
