import TransferError from './TransferError'

/**
 * API/ HTTP status code 4xx, 5xx error (BadResponseException in Guzzle)
 * {@link https://github.com/directus/directus/tree/v9.19.1/api/src/exceptions}
 */
export default class ApiError extends TransferError {
  /**
   * @inheritdoc
   */
  constructor(
    request: Request,
    public response: Response,
  ) {
    super(request, 'An error occured while fetching the data')

    Object.setPrototypeOf(this, ApiError.prototype)
  }
}

ApiError.prototype.name = 'ApiError'
