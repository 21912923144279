import {
  type ErrorInfo,
  Component,
} from 'preact'
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonPage,
} from '@ionic/react'

import { routes, generatePath } from '../../navigation'

type ErrorBoundaryProps = {
  envMode: string,
}

type ErrorBoundaryState = {
  error: Error | null,
}

/**
 * Error boundary component
 * @see https://reactjs.org/docs/error-boundaries.html
 */
class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  /** @inheritdoc */
  state: ErrorBoundaryState = {
    error: null,
  }

  /**
   * @inheritdoc
   */
  static getDerivedStateFromError(error: Error): Pick<ErrorBoundaryState, 'error'> {
    return { error }
  }

  /**
   * @inheritdoc
   */
  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // no-op
    // TODO: Log error
  }

  /**
   * Reset error boundary
   * NOT USERD
   * Do not use react-router methods which require to be wrapped with <Router>
   */
  resetErrorBoundary() {
    this.setState({ error: null }, () => {
      // Redirect to start page
      window.location.assign(generatePath(routes.start))
    })
  }

  /**
   * @inheritdoc
   */
  render() {
    // All is good
    if (!this.state.error) {
      return this.props.children
    }

    // Note: ion button doesn't use router so state is reset to default
    const fallbackRoute = generatePath(routes.start)

    // Fallback UI
    return (
      <IonPage className="m1-page m1-page--error">
        <IonContent>
          <div className="m1-page__error-wrapper">
            <IonCard className="m1-card">
              {/** Header */}
              <IonCardHeader>
                <IonCardTitle>
                  {'Błąd aplikacji'}
                </IonCardTitle>
              </IonCardHeader>
              {/** Details */}
              {this.props.envMode !== 'production' &&
                <IonCardContent>
                  <code>
                    {this.state.error.name}<br />
                    {this.state.error.message}
                  </code>
                </IonCardContent>
              }
              {/** Reset button */}
              <IonButton
                color="primary"
                fill="solid"
                routerLink={fallbackRoute}
                routerDirection="none"
              >
                {'Zrestartuj aplikację'}
              </IonButton>
            </IonCard>
          </div>
        </IonContent>
      </IonPage>
    )
  }
}

export default ErrorBoundary

/**
 * Error boundary Higher-Order Component
 * NOT USED
 * @see https://reactjs.org/docs/higher-order-components.html
 */
export function withErrorBoundary<P>(
  WrappedComponent: preact.ComponentType<P>,
  errorBoundaryProps: ErrorBoundaryProps,
) {
  // Format for display in DevTools
  const componentDisplayName = WrappedComponent.displayName || WrappedComponent.name || 'unknown'

  const Wrapped: preact.ComponentType<P> = props =>
    <ErrorBoundary {...errorBoundaryProps}>
      <WrappedComponent {...props} />
    </ErrorBoundary>

  Wrapped.displayName = `withErrorBoundary(${componentDisplayName})`

  return Wrapped
}
