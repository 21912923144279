import './GarbageTypeIndicator.css'

/**
 * Garbage type indicator (color)
 * May use ion-chip
 * @link https://ionicframework.com/docs/api/chip
 */
const GarbageTypeIndicator: preact.FunctionComponent<{
  color: string | null,
  slot?: string,
}> = ({
  color,
  slot
}) => {
  return (
    <span
      slot={slot}
      className="m1-gc-type-indicator"
      style={{ backgroundColor: color }}
    ></span>
  )
}

export default GarbageTypeIndicator
