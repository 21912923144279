import { SWRConfig } from 'swr'
import { Route } from 'react-router-dom'
import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  isPlatform,
  setupIonicReact,
} from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'

import { AppStateProvider } from '../../state'
import { ApiProvider as DirectusApiProvider } from '../../data/api'
import { ApiProvider as SyngeosApiProvider } from '../../data/syngeos'
import { OrganisationProvider } from '../../contexts/organisation'
import { FirebaseAnalyticsProvider } from '../../contexts/firebase-analytics'
import { routes } from '../../navigation'
import { TransferError, ConnectError } from '../../utils/Error'

import NativeHandlers from '../NativeHandlers'
import ErrorBoundary from '../ErrorBoundary'
import Menu from '../Menu'
import ReloadPrompt from '../ReloadPrompt'
import StartPage from '../../pages/Start'
import OrganisatioListPage from '../../pages/OrganisationsList'
import AboutCityPage from '../../pages/AboutCity'
import CityPage from '../../pages/City'
import NewsListPage from '../../pages/NewsList'
import NewsItemPage from '../../pages/NewsItem'
import AirQualityStationsListPage from '../../pages/AirQualityStationsList'
import GarbageCollectionsPage from '../../pages/GarbageCollections'
import CleanAirPage from '../../pages/CleanAir'
import NotFoundPage from '../../pages/NotFound'
import SplashPage from '../../pages/Splash'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import '../../theme/variables.css'

/* Custom */
import '../../theme/theme.css'

setupIonicReact()

/**
 * App component
 */
const App: preact.FunctionComponent = () => {
  return (
    <IonSplitPane contentId="main" className="m1-canvas">
      <Menu contentId="main" />
      <IonRouterOutlet id="main">
        <Route path={routes.start} exact component={() => <StartPage />} />
        <Route path={routes.citiesList} exact component={() => <OrganisatioListPage />} />
        <Route path={routes.city} exact component={() => <CityPage />} />
        <Route path={routes.aboutCity} exact component={() => <AboutCityPage />} />
        <Route path={routes.newsList} exact component={() => <NewsListPage />} />
        <Route path={routes.newsItem} exact component={() => <NewsItemPage />} />
        <Route path={routes.airQualityStationsList} exact component={() => <AirQualityStationsListPage />} />
        <Route path={routes.garbageCollections} exact component={() => <GarbageCollectionsPage />} />
        <Route path={routes.cleanAir} exact component={() => <CleanAirPage />} />
        <Route component={() => <NotFoundPage />} />
      </IonRouterOutlet>
    </IonSplitPane>
  )
}

/**
 * App with providers
 */
const AppWrapper: preact.FunctionComponent<{
  env: ImportMetaEnv,
}> = ({
  env,
}) => {
  return (
    <IonApp>
      <ErrorBoundary envMode={env.MODE}>
        <AppStateProvider fallback={<SplashPage text="Inicjowanie ustawień…" />}>
          <SWRConfig value={{
            // Disable on dev
            revalidateOnFocus: env.PROD,
            revalidateOnReconnect: env.PROD,
            // Retry only on connection errors
            shouldRetryOnError: (error: TransferError) =>
              error instanceof ConnectError,
            // Set on each useSWR
            fetcher: undefined,
          }}>
            <DirectusApiProvider
              baseUrl={env.VITE_DIRECTUS_URL}
              accessToken={env.VITE_DIRECTUS_ACCESS_TOKEN}
              fallback={<SplashPage text="Inicjowanie pamięci podręcznej…" />}
            >
              <SyngeosApiProvider
                baseUrl={env.VITE_SYNGEOS_API_URL}
              >
                <FirebaseAnalyticsProvider envEnabled={env.VITE_FIREBASE_ANALYTICS_ENABLED === '1'}>
                  <IonReactRouter basename={env.BASE_URL}>
                    <OrganisationProvider fallback={<SplashPage text="Wczytuję dane miasta…" />}>
                      <App />
                      {isPlatform('hybrid')
                        ? <NativeHandlers />
                        : <ReloadPrompt />
                      }
                    </OrganisationProvider>
                  </IonReactRouter>
                </FirebaseAnalyticsProvider>
              </SyngeosApiProvider>
            </DirectusApiProvider>
          </SWRConfig>
        </AppStateProvider>
      </ErrorBoundary>
    </IonApp>
  )
}

export default AppWrapper
