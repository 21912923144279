import {
  IonPage,
  IonText,
} from '@ionic/react'

import icon from './images/icon-adaptive-foreground.svg?raw'

import './SplashPage.css'

/**
 * Splash page
 */
const SplashPage: preact.FunctionComponent<{
  text?: string,
}> = ({
  text = undefined,
}) => (
  <IonPage className="m1-page m1-page--splash ion-justify-content-center ion-align-items-center">
    <div className="m1-page--splash__logo">
      <img
        className="m1-page--splash__logo-img"
        src={`data:image/svg+xml;utf8,${encodeURIComponent(icon)}`}
        alt="lokalniej.pl"
      />
    </div>

    {text &&
      <IonText className="m1-page--splash__text ion-margin-horizontal ion-text-center">
        {text}
      </IonText>
    }
  </IonPage>
)

export default SplashPage
