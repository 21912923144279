import type { SWRConfiguration, KeyedMutator } from 'swr'
import useSWR from 'swr'

import type {
  IGlobalQueryParams,
  ManyItems,
} from './types'
import { useApi } from './ApiContext'
import { TransferError } from '../../utils/Error'

// Note: Do not use as SWR fallbackData option as it would break isLoading detection
const fallbackData: ManyItems<never> = {
  data: [],
}

/**
 * Generic list
 * @link https://docs.directus.io/reference/items/
 */
export default function useItems<Schema extends object> (
  resource: string,
  query: IGlobalQueryParams<Schema>,
  shouldFetch: boolean = true,
  customConfig?: SWRConfiguration<ManyItems<Schema>, TransferError>
): {
  data: ManyItems<Schema>['data'],
  meta: ManyItems<Schema>['meta'],
  error: TransferError | undefined,
  isLoading: boolean,
  mutate: KeyedMutator<ManyItems<Schema>>
} {
  const path = `items/${resource}`
  const { config } = useApi()

  const {
    data,
    error,
    mutate,
  } = useSWR<ManyItems<Schema>, TransferError>(shouldFetch ? { path, query } : null, {
    fallbackData: undefined,
    ...config,
    ...customConfig,
  })

  return {
    data: data ? data.data : fallbackData.data,
    meta: data ? data.meta : fallbackData.meta,
    error: shouldFetch && !data ? error : undefined,
    isLoading: shouldFetch && !data && !error,
    mutate,
  }
}
