const formatter = new Intl.DateTimeFormat('pl-PL', {
  day: 'numeric',
  month: 'long',
  weekday: 'long',
})

/**
 * Format local date to yyyy-mm-dd format
 */
export function toSqlDate(date: Date): string {
  return [
    date.getFullYear(),
    date.getMonth() + 1,
    date.getDate()
  ]
    .map(number => number
      .toString()
      .padStart(2, '0')
    )
    .join('-')
}

/**
 * Format local date to yyyy-mm
 */
export function toPathYearMonth(date: Date, offset: number = 0): string {
  date = new Date(date)
  date.setMonth(date.getMonth() + offset)

  return [
    date.getFullYear(),
    date.getMonth() + 1,
  ]
    .map(number => number
      .toString()
      .padStart(2, '0')
    )
    .join('-')
}

/**
 * Create new Date object with +- month
 */
export function withMonthOffset(date: Date, offset: number): Date {
  date = new Date(date)
  date.setMonth(date.getMonth() + offset)

  return date
}

/**
 * Format to locale date
 */
export function toLocaleDate(date: Date): string {
  return formatter.format(date)
}

export function isSameDay(dateA: Date, dateB: Date): boolean {
  return (
    dateA.getFullYear() === dateB.getFullYear() &&
    dateA.getMonth() === dateB.getMonth() &&
    dateA.getDate() === dateB.getDate()
  )
}
