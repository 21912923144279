import { useMemo } from 'preact/hooks'
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
} from '@ionic/react'
import {
  chevronBack,
  chevronForward,
} from 'ionicons/icons'
import clsx from 'clsx'

import type {
  TCalendarRange,
  TDayEvent,
  TIndicatorColorResolver,
} from './types'
import { isSameDay, withMonthOffset } from '../../utils/date'

import './Calendar.css'

/**
 * I18n formatters
 * @TODO Use i18n package
 */
const yearMonthFormatter = new Intl.DateTimeFormat('pl-PL', {
  year: 'numeric',
  month: 'long',
})

const weekdaysFormatter = new Intl.DateTimeFormat('pl-PL', {
  weekday: 'narrow',
})

const weekdaysRange = [1, 2, 3, 4, 5, 6, 7].map(day =>
  new Date(1970, 0, 4 + day)
)

/**
 * Calendar component
 */
const Calendar: preact.FunctionComponent<{
  today: Date,
  referenceDate: Date,
  selectedDate: Date | null,
  calendarRange: TCalendarRange,
  daysData: Map<Date, TDayEvent[]>,
  setReferenceDate: (date: Date) => void,
  handleDateSelect: (date: Date) => void,
  getIndicatorColor: TIndicatorColorResolver,
}> = ({
  today,
  referenceDate,
  selectedDate,
  calendarRange,
  daysData,
  setReferenceDate,
  handleDateSelect,
  getIndicatorColor,
}) => {
  return (
    <IonCard className="m1-card">
      <IonCardHeader>
        <IonCardTitle className="m1-card__title m1-calendar-card-title">
          {/** Month and Year */}
          <span className="m1-calendar__date ion-text-capitalize">
            {yearMonthFormatter.format(referenceDate)}
          </span>
          {/** Prev/ Next buttons */}
          <span>
            <IonButton
              className="m1-calendar__switch-button"
              color="dark"
              fill="clear"
              onClick={() => setReferenceDate(withMonthOffset(referenceDate, -1))}
            >
              <IonIcon icon={chevronBack} />
            </IonButton>
            <IonButton
              className="m1-calendar__switch-button"
              color="dark"
              fill="clear"
              onClick={() => setReferenceDate(withMonthOffset(referenceDate, +1))}
            >
              <IonIcon icon={chevronForward} />
            </IonButton>
          </span>
        </IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <div className="m1-calendar">
          {/** Day of week */}
          <div className="m1-calendar__days-of-week">
            {useMemo(() => weekdaysRange.map((day, index) =>
              <span
                key={index}
                className={day.getDay() ? undefined : 'm1-calendar-day--sunday'}
              >
                {weekdaysFormatter.format(day)}
              </span>
            ), [weekdaysRange, weekdaysFormatter])}
          </div>
          {/** Dates */}
        </div>
        <div className="m1-calendar__dates" data-offset={calendarRange.start.getDay()}>
          {Array.from(daysData).map(([day, events]) =>
            <button
              key={day.getTime()}
              className={clsx('m1-calendar-day', {
                'm1-calendar-day--today': isSameDay(today, day),
                'm1-calendar-day--sunday': !day.getDay(),
                'm1-calendar-day--selected': selectedDate && isSameDay(selectedDate, day),
                'm1-calendar-day--past': day < today,
              })}
              type="button"
              disabled={!events.length}
              onClick={() => handleDateSelect(day)}
            >
              {events.length !== 0 &&
                <ul className="m1-calendar-indicators">
                  {events.map(event =>
                    <li
                      key={event.id}
                      className="m1-calendar-indicator"
                      style={{ backgroundColor: getIndicatorColor(event) }}
                    ></li>
                  )}
                </ul>
              }
              <span className="m1-calendar-date">
                {day.getDate()}
              </span>
            </button>
          )}
        </div>
      </IonCardContent>
    </IonCard>
  )
}

export default Calendar
