/**
 * Action creators
 */
import type { IOrganisationSlug } from '../organisation'
import type { IGarbageZoneId } from './types'

import TYPE from './action-types'

/**
 * Set garbage zone for organisation
 */
export const setGarbageZone = (organisation: IOrganisationSlug, garbageZone: IGarbageZoneId) => ({
  type: TYPE.SET_GARBAGE_ZONE,
  payload: {
    organisation,
    garbageZone,
  },
})

export type IOrganisationPreferencesAction =
  ReturnType<typeof setGarbageZone>
