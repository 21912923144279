import type { StateStorage } from 'zustand/middleware'
import { Preferences } from '@capacitor/preferences'

/**
 * Persistent state storage factory (Capacitor storage keyval wrapper)
 * Default configured group is 'CapacitorStorage'
 * @link https://capacitorjs.com/docs/apis/preferences
 * @link https://docs.pmnd.rs/zustand/persisting-store-data#how-can-i-use-a-custom-storage-engine?
 */
export default function StateStorageFactory (): StateStorage {
  return {
    getItem: name =>
      Preferences
        .get({ key: name })
        .then(getResult => getResult.value)
    ,

    setItem: (name, value) =>
      Preferences.set({ key: name, value })
    ,

    removeItem: name =>
      Preferences.remove({ key: name })
    ,
  }
}
