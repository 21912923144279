import { useState, useEffect } from 'preact/hooks'
import { useStore } from '.'

/**
 * Show fallback component untile persistent state is fully hydrated
  @link https://github.com/pmndrs/zustand/wiki/Persisting-the-store's-data#how-can-i-check-if-my-store-has-been-hydrated
 */
export const AppStateProvider: preact.FunctionComponent<{
  fallback?: JSX.Element,
}> = ({
  children,
  fallback = null
}) => {
  const [ hydrated, setHydrated ] = useState(useStore.persist.hasHydrated)

  useEffect(() => {
    const unsubHydrate = useStore.persist.onHydrate(() => setHydrated(false))
    const unsubFinishHydration = useStore.persist.onFinishHydration(() => setHydrated(true))

    setHydrated(useStore.persist.hasHydrated())

    return () => {
      unsubHydrate()
      unsubFinishHydration()
    }
  }, [])

  return hydrated
    ? children as JSX.Element
    : fallback
}
