import type { IAction } from '../types'
import type { IOrganisationSlug } from './types'
import initialState from './initial-state'
import TYPE from './action-types'

export default function reducer (state: IOrganisationSlug = initialState, action: IAction): IOrganisationSlug {
  switch (action.type) {
    case TYPE.SET:
      return action.payload
  }

  return state
}
