import { createContext } from 'preact'
import { useContext, useMemo } from 'preact/hooks'
import type { SWRConfigurationWithProvider } from '../types'

import createFetcher from './fetcher'

/**
 * API configuration context object
 */
const SyngeosApiConfigurationContext = createContext<SWRConfigurationWithProvider>({})

/**
 * API configuration hook
 */
export function useApiConfiguration(): SWRConfigurationWithProvider {
  return useContext(SyngeosApiConfigurationContext)
}

/**
 * API Provider
 */
export const ApiProvider: preact.FunctionComponent<{
  baseUrl: string,
}> = ({
  baseUrl,
  children,
}) => {
  // Create mock cache that will overide upper level SWRConfig option
  const provider = useMemo(() => () => new Map(), [])

  const config: SWRConfigurationWithProvider = {
    fetcher: createFetcher(baseUrl),
    provider,
  }

  return (
    <SyngeosApiConfigurationContext.Provider value={config}>
      {children}
    </SyngeosApiConfigurationContext.Provider>
  )
}

/**
 * Higher-Order Component
 * NOT USED
 * @TODO Use with caution
 */
export function withApi<P>(
  Component: preact.ComponentType<P>,
  apiProviderProps: { baseUrl: string },
) {
  const componentDisplayName = Component.displayName || Component.name || 'unknown'

  const Wrapped: preact.ComponentType<P> = props =>
    <ApiProvider {...apiProviderProps}>
      <Component {...props} />
    </ApiProvider>

  Wrapped.displayName = `withApi(${componentDisplayName})`

  return Wrapped
}
