import { useState, useEffect, useMemo, useRef } from 'preact/hooks'
import { useHistory } from 'react-router'
import type {
  IonSearchbarCustomEvent,
  SearchbarChangeEventDetail,
} from '@ionic/core'
import {
  IonButton,
  IonButtons,
  IonPage,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonModal,
  IonHeader,
  IonToolbar,
  IonSearchbar,
  IonTitle,
  useIonViewWillEnter,
  useIonViewWillLeave,
  useIonViewDidLeave,
} from '@ionic/react'
import { Virtuoso } from 'react-virtuoso'

import type { TOrganisationSchema } from '../../models/api'
import { useItems } from '../../data/api'
import { useStore } from '../../state'
import useIonViewVisibility from '../../hooks/useIonViewVisibility'
import { set as setOrganisationSlug } from '../../state/organisation'

import { routes, generatePath } from '../../navigation'
import { useFirebaseAnalytics } from '../../contexts/firebase-analytics'

import TransferErrorAlert from '../../components/TransferErrorAlert'
import SplashPage from '../Splash/SplashPage'

import { ReactComponent as Logo } from './images/logo.svg'

import './OrganisationsListPage.css'
import LoadingOverlay from '../../components/LoadingOverlay'

type MinOrganisationSchema = Pick<TOrganisationSchema, 'name' | 'slug'>

/**
 * City switcher
 *
 * Note: Not trivial to add images to ion-select-option (style attribute doesn't work)
 *       @link https://ionicframework.com/docs/api/select-option#customizing-individual-options-1
 */
const OrganisationsListPage: preact.FunctionComponent = () => {
  // TODO: Switch to useNavigate when on react-router@^6
  const history = useHistory()

  const modal = useRef<HTMLIonModalElement>(null)

  const [ organisation, setOrganisation ] = useState<MinOrganisationSchema | null>(null)
  const [ searchText, setSearchText ] = useState<string>('')

  const dispatch = useStore(state => state.dispatch)

  const isVisble = useIonViewVisibility()

  const { setCurrentScreen: setFACurrentScreen } = useFirebaseAnalytics()

  const {
    data: organisations,
    error: organisationsError,
    isLoading: isLoadingOrganisations,
    mutate: organisationsMutate,
  } = useItems<MinOrganisationSchema>('organisations', {
    fields: ['name', 'slug'],
    sort: ['name'],
    limit: -1,
  }, isVisble)

  // Filter by search text
  const organisationsFiltered = useMemo(() => {
    if (!searchText || searchText.length < 3){
      return organisations
    }

    const query = searchText.toLocaleLowerCase('pl')

    return organisations.filter(organisation =>
      organisation.name.toLocaleLowerCase('pl').indexOf(query) === 0
    )
  }, [organisations, searchText])

  // Sort by name (ie. ś > s)
  const organisationsSorted = useMemo(() =>
    organisationsFiltered.sort((organisationA, organisationB) =>
      organisationA.name.localeCompare(organisationB.name, ['pl-PL'])
    ),
    [organisationsFiltered]
  )

  const organisationsList = organisationsSorted

  // Redirect
  useEffect(() => {
    if (!organisation) {
      return
    }

    // Save in state
    dispatch(setOrganisationSlug(organisation.slug))

    // Redirect
    history.push(generatePath(routes.city, { organisationSlug: organisation.slug }))

    // Manually dismiss modal, as useIonViewLeave doesn't trigger at first time
    dismissModal()
  }, [organisation])

  // Set screen name
  useEffect(() => {
    setFACurrentScreen('CitiesList')
  }, [])

  // Reset state on back button
  useIonViewWillEnter(() =>
    setOrganisation(null)
  )

  // Close modal on exit
  useIonViewDidLeave(() =>
    dismissModal()
  )

  /**
   * Handle searchbar change
   */
  const handleSearchChange = (event: IonSearchbarCustomEvent<SearchbarChangeEventDetail>): void => {
    setSearchText(event.target.value!)
  }

  /**
   * Handle modal close
   */
  const dismissModal = () =>
    modal.current?.dismiss(organisation, organisation ? 'confirm' : 'cancel')

  if (isLoadingOrganisations) {
    return <SplashPage text="Wczytuję listę miast…" />
  }

  return (
    <IonPage className="m1-page m1-page--start">
      <IonContent>
        <div className="m1-start-page__wrapper">
          <Logo
            className="m1-start-page__logo ion-margin"
            width="256"
            height="256"
            viewBox="0 0 256 256"
          />

          <IonButton
            id="m1-start-page-select"
            className="m1-start-page__select ion-margin"
            fill="outline"
            shape="round"
          >
            {'Wybierz miasto'}
          </IonButton>
        </div>
      </IonContent>

      {/** Selection modal */}
      <IonModal
        ref={modal}
        trigger="m1-start-page-select"
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>
              {'Wybierz miasto z listy'}
            </IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={dismissModal}>
                {'Zamknij'}
              </IonButton>
            </IonButtons>
          </IonToolbar>
          <IonToolbar>
            <IonSearchbar
              autocomplete="address-level2"
              autocorrect="on"
              inputmode="search"
              placeholder={'Wyszukaj'}
              value={searchText}
              onIonChange={handleSearchChange}
            />
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonList
            className="m1-organisations-list"
            lines="full"
          >
            <Virtuoso
              className="ion-content-scroll-host"
              data={organisationsList}
              defaultItemHeight={50}
              itemContent={(index, organisation) =>
                <div
                  className="m1-organisations-list__item-wrapper"
                  key={organisation.slug}
                >
                  <IonItem
                    button
                    detail={false}
                    lines={index === organisationsList.length - 1 ? 'none' : undefined}
                    onClick={() => setOrganisation(organisation)}
                  >
                    <IonLabel>
                      {organisation.name}
                    </IonLabel>
                  </IonItem>
                </div>
              }
            />
          </IonList>
        </IonContent>
      </IonModal>

      {/** Loading indicator */}
      <LoadingOverlay isOpen={isLoadingOrganisations} />

      {/** Error dialog */}
      {organisationsError &&
        <TransferErrorAlert
          error={organisationsError}
          mutate={organisationsMutate}
        />
      }
    </IonPage>
  )
}

export default OrganisationsListPage
