import { useState } from 'preact/hooks'
import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonItem,
  IonLabel,
  useIonViewDidLeave,
} from '@ionic/react'

import { useOrganisation } from '../../contexts/organisation'
import useIonViewVisibility from '../../hooks/useIonViewVisibility'

import Page from '../../components/Page'
import Modal from '../../components/Modal'
import Markdown from '../../components/Markdown'
import InteractivePoint from '../../components/InteractivePoint'
import interactivePoints from './interactivePoints'

import { ReactComponent as CleanAirIcon } from '../../images/pages/clean-air.svg'
import cleanAirImg from './images/clean-air.svg'
import cleanAirLogoImg from './images/cliean-air-logo.svg'

import './CleanAirPage.css'

/**
 * Clean air page
 */
const CleanAirPage: preact.FunctionComponent = () => {
  const organisation = useOrganisation()

  const [ selectedPoint, setSelectedPoint ] = useState<number | null>(null)
  const [ showContent, setShowContent ] = useState<boolean>(false)

  const isVisible = useIonViewVisibility()

  // Close modals on exit
  useIonViewDidLeave(() => {
    setSelectedPoint(null)
    setShowContent(false)
  })

  return (
    <Page
      screenName="CleanAir"
      theme={{
        name: 'clean-air',
        title: 'Program Czyste Powietrze',
        icon: CleanAirIcon,
      }}
      isLoading={!organisation}
    >
      <div className="m1-clean-air-canvas">
        <div className="m1-clean-air-element m1-clean-air__tagline ion-padding ion-text-center">
          <p className="ion-no-margin">
            {'Czyste powietrze zależy także od Ciebie'}<br />
            {'sprawdź co możesz zrobić'}
          </p>
          <img
            className="m1-clean-air__logo"
            src={cleanAirLogoImg}
            alt="Program Czyste Powietrze"
          />
        </div>

        {organisation?.clean_air_content &&
          <div className="m1-clean-air-element m1-clean-air__desc ion-padding ion-text-center">
            <p>
              {'Dofinansowanie na wymianę pieca'}
            </p>
            <IonButton
              shape="round"
              onClick={() => setShowContent(true)}
            >
              {'Więcej informacji'}
            </IonButton>
          </div>
        }

        <img
          className="m1-clean-air-canvas__background"
          src={cleanAirImg}
          alt=""
        />

        {interactivePoints.map((interactivePoint, index) =>
          <InteractivePoint
            key={index}
            title={interactivePoint.header}
            coords={interactivePoint.coords}
            onClick={() => setSelectedPoint(index)}
          />
        )}
      </div>

      {/** Point details modal */}
      <Modal
        isOpen={selectedPoint !== null}
        animated={isVisible}
        onClose={() => setSelectedPoint(null)}
      >
        <IonAccordionGroup value={selectedPoint?.toString()}>
          {interactivePoints.map((interactivePoint, index) =>
            <CleanAirAccordion
              key={index}
              value={index.toString()}
              header={interactivePoint.header}
              icon={interactivePoint.icon}
              content={interactivePoint.content}
            />
          )}
        </IonAccordionGroup>
      </Modal>

      {/** Text modal */}
      <Modal
        isOpen={showContent}
        animated={isVisible}
        onClose={() => setShowContent(false)}
      >
        {organisation?.clean_air_content &&
          <Markdown markdown={organisation.clean_air_content} />
        }
      </Modal>
    </Page>
  )
}

/**
 * Clean Air accordion item
 */
const CleanAirAccordion: preact.FunctionComponent<{
  /** Header text */
  header: string,
  /** Icon */
  icon: preact.FunctionComponent<JSX.SVGAttributes<SVGSVGElement> & { title?: string }>,
  /** Content */
  content: string,
  /** ID */
  value: string,
}> = ({
  header,
  icon: Icon,
  content,
  value,
}) =>
  <IonAccordion
    className="m1-clean-air-accordion"
    value={value}
  >
    <IonItem slot="header">
      <Icon
        className="m1-clean-air-accordion__icon"
        slot="start"
        title={header}
      />
      <IonLabel className="m1-label-full-text">
        {header}
      </IonLabel>
    </IonItem>
    <div
      slot="content"
      className="ion-padding"
    >
      <Markdown markdown={content} />
    </div>
  </IonAccordion>

export default CleanAirPage
