import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
} from '@ionic/react'

import type {
  TOfficeContactSchema,
  TLocation,
} from '../../models/api'

import Markdown from '../Markdown'

import './OfficeContact.css'

/**
 * Office contact used on both About and Clean Air pages
 */
const OfficeContact: preact.FunctionComponent<{
  item: TOfficeContactSchema,
}> = ({
  item,
}) =>
  <IonCard className="m1-contact">
    <IonCardHeader className="m1-contact__header">
      <IonCardTitle className="m1-contact__title">
        {item.name.replace(/, ?/g, '\n')}
      </IonCardTitle>
    </IonCardHeader>
    <IonCardContent className="ion-no-padding">
      <ul className="m1-contact__list">
        {/** Address */}
        {item.address &&
          <li>
            <a
              href={createGoofleMapsUrlSearch(item.address).href}
              target="contact-address"
              rel="noopener noreferrer"
              className="m1-link"
            >
              {item.address.replace(/, ?/g, '\n')}
            </a>
          </li>
        }
        {/** Phone numbers */}
        {item.phone_number?.split(/, ?/g).map(phoneNumber =>
          <li key={phoneNumber}>
            Tel.: <a
              href={`tel:${sanitizePhoneNumber(phoneNumber)}`}
              target="contact-phone"
            >
              {phoneNumber}
            </a>
          </li>
        )}
        {/** Fax numbers */}
        {item.fax_number?.split(/, ?/g).map(faxNumber =>
          <li key={faxNumber}>
            Fax: <a
              href={`tel:${sanitizePhoneNumber(faxNumber)}`}
              target="contact-fax"
            >
              {faxNumber}
            </a>
          </li>
        )}
        {/** Email numbers */}
        {item.email?.split(/, ?/g).map(email =>
          <li key={email}>
            E-mail: <a
              href={`mailto:${email}`}
              target="contact-email"
            >
              {email}
            </a>
          </li>
        )}
        {/** Notes */}
        {item.notes &&
          <li>
            <Markdown markdown={item.notes} />
          </li>
        }
      </ul>
    </IonCardContent>

  </IonCard>


export default OfficeContact

/**
 * Remove special characters from phone number so it may be used in anchor element
 * {@link https://www.rfc-editor.org/rfc/rfc3966#section-5.1.1}
 * {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attr-href}
 */
function sanitizePhoneNumber(phoneNumber: string): string {
  return phoneNumber
    .replace(/\s/g, '')
}

/**
 * Google Maps Location search
 * @param destination - Search query or location coordinates
 * @link https://developers.google.com/maps/documentation/urls/get-started#search-action
 */
function createGoofleMapsUrlSearch(destination: string | TLocation): URL {
  const url = new URL('https://www.google.com/maps/search/')

  const query = typeof destination === 'string'
    ? destination
    : destination.coordinates
      .reverse()
      .map(coord => coord.toFixed(7))
      .toString()

  url.searchParams.set('api', '1')
  url.searchParams.set('query', query)

  return url
}
