import clsx from 'clsx'

import type { TImage } from '../../models/api'
import { Preset } from '../../constants/asset'

import ApiImage from '../ApiImage'

import './NewsItemImage.css'

/**
 * News item image
 */
const NewsItemImage: preact.FunctionComponent<{
  /** Asset object */
  asset: TImage,
  /** Element class name */
  className?: string,
  /** Show meta info */
  showMeta?: boolean,
  /** News list item */
  isListItem?: boolean,
}> = ({
  asset,
  className = null,
  showMeta = false,
  isListItem = false,
}) =>
  <figure className={clsx('m1-news-item-image', className, {
    'm1-news-item-image--news-list-item': isListItem,
  })}>
    <ApiImage
      asset={asset}
      preset={Preset.NewsImage}
      alt={asset.title ?? undefined}
      wrapperClassName="m1-news-item-image__wrapper"
      className="m1-news-item-image__image-element"
    />
    {showMeta && (asset.title || asset.source) &&
      <figcaption className="m1-news-item-image__caption">
        {/** Title */}
        {asset.title &&
          <span className="m1-news-item-image__title">{asset.title}</span>
        }
        {/** Source */}
        {asset.source &&
          <span className="m1-news-item-image__source">{asset.source}</span>
        }
      </figcaption>
    }
  </figure>

export default NewsItemImage
