import { useState, useEffect } from 'preact/hooks'
import { Redirect } from 'react-router'

import { useStore } from '../../state'
import { routes, generatePath } from '../../navigation'

/**
 * Start page
 * Redirect to city page or cities picker
 */
const StartPage: preact.FunctionComponent = () => {
  // Read current organisation from persistent storage
  const organisationSlug = useStore(state => state.organisation)

  /**
   * Use redirect after a while to prevent ion-menu messsage
   * Menu: must have a "content" element to listen for drag events on.
   */
  const [ route, setRoute ] = useState<string | null>(null)

  useEffect(() => {
    // Resolve route
    const route = organisationSlug
      ? generatePath(routes.city, { organisationSlug })
      : generatePath(routes.citiesList)

    setRoute(route)
  }, [])

  return route ? <Redirect to={route} /> : null
}

export default StartPage
