import { useLocation } from 'react-router'
import {
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import {
  balloonSharp, balloonOutline,
  homeSharp, homeOutline,
  partlySunnySharp, partlySunnyOutline,
  newspaperSharp, newspaperOutline,
  searchSharp, searchOutline,
  shieldOutline, shieldSharp,
  trashSharp, trashOutline,
} from 'ionicons/icons'

import { useOrganisation } from '../../contexts/organisation'
import { routes, generatePath } from '../../navigation'

import { Preset } from '../../constants/asset'

import ApiImage from '../ApiImage'
import { ReactComponent as LogoVertical } from './images/logo-vertical.svg'

import './Menu.css'

interface AppPage {
  url: string,
  iosIcon: string,
  mdIcon: string,
  title: string,
}

const appPages: AppPage[] = [
  // Switch City
  {
    title: 'Zmień miasto',
    url: routes.citiesList,
    iosIcon: searchOutline,
    mdIcon: searchSharp,
  }
]

/**
 * Side menu component
 * @see https://github.com/ionic-team/starters/blob/main/react/official/sidemenu/src/components/Menu.tsx
 */
const Menu: preact.FunctionComponent<{
  /** The ion-router-outlet Id */
  contentId: string,
}> = ({
  contentId,
}) => {
  const location = useLocation()
  const organisation = useOrganisation()

  // Must render menu, or it will not pop up later
  if (!organisation) {
    return (
      <IonMenu
        className="ion-hide"
        contentId={contentId}
        side="start"
        type="overlay"
      />
    )
  }

  const organisationPages: AppPage[] = [
    // Home
    {
      title: 'Start',
      url: generatePath(routes.city, { organisationSlug: organisation.slug }),
      iosIcon: homeOutline,
      mdIcon: homeSharp,
    },
    // News
    {
      title: 'Aktualności',
      url: generatePath(routes.newsList, { organisationSlug: organisation.slug }),
      iosIcon: newspaperOutline,
      mdIcon: newspaperSharp,
    },
    // Gargbage Collections
    {
      title: 'Odbiór śmieci',
      url: generatePath(routes.garbageCollections, { organisationSlug: organisation.slug }),
      iosIcon: trashOutline,
      mdIcon: trashSharp,
    },
    // Air Quality
    {
      title: 'Jakość powietrza',
      url: generatePath(routes.airQualityStationsList, { organisationSlug: organisation.slug }),
      iosIcon: partlySunnyOutline,
      mdIcon: partlySunnySharp,
    },
    // Clean Air
    {
      title: 'Czyste Powietrze',
      url: generatePath(routes.cleanAir, { organisationSlug: organisation.slug }),
      iosIcon: balloonOutline,
      mdIcon: balloonSharp,
    },
    // About city
    {
      title: 'O mieście',
      url: generatePath(routes.aboutCity, { organisationSlug: organisation.slug }),
      iosIcon: shieldOutline,
      mdIcon: shieldSharp,
    },
  ]

  return (
    <IonMenu
      contentId={contentId}
      side="start"
      type="overlay"
    >
      <IonHeader className="ion-no-border">
        <IonToolbar className="m1-menu-toolbar">
          <IonTitle className="m1-menu-title">
            <LogoVertical
              className="m1-logo-vertical"
              title="lokalniej.pl"
            />
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {organisationPages.map((page, index) =>
            <IonMenuToggle
              key={index}
              autoHide={false}
            >
              <IonItem
                className={location.pathname === page.url ? 'selected' : ''}
                routerLink={page.url}
                routerDirection="none"
                lines="none"
                detail={false}
              >
                <IonIcon
                  slot="start"
                  ios={page.iosIcon}
                  md={page.mdIcon}
                />
                <IonLabel>
                  {page.title}
                </IonLabel>
              </IonItem>
            </IonMenuToggle>
          )}
        </IonList>

        <IonList>
          {appPages.map((page, index) =>
            <IonMenuToggle
              key={index}
              autoHide={false}
            >
              <IonItem
                routerLink={page.url}
                routerDirection="none"
                lines="none"
                detail={false}
              >
                <IonIcon
                  slot="start"
                  ios={page.iosIcon}
                  md={page.mdIcon}
                />
                <IonLabel>
                  {page.title}
                </IonLabel>
              </IonItem>
            </IonMenuToggle>
          )}
        </IonList>

        {organisation.logo &&
          <ApiImage
            asset={organisation.logo}
            preset={Preset.OrganisationLogo}
            wrapperClassName="m1-coa ion-margin"
            className="m1-coa__element"
            alt={organisation.name}
          />
        }
      </IonContent>
    </IonMenu>
  )
}

export default Menu
