import { IonIcon } from '@ionic/react'
import {
  earthSharp,
  earthOutline,
} from 'ionicons/icons'

import type { TNews } from '../../models'

import Tags from '../Tags'

import './NewsItemBar.css'

/**
 * Tags + Global news indicoator
 */
const NewsItemBar: preact.FunctionComponent<{
  newsItem: TNews,
}> = ({
  newsItem,
}) =>
    <div className="m1-news-item-bar">
      <Tags items={newsItem.tags} />
      {!newsItem.organisation &&
        <IonIcon
          color="medium"
          md={earthSharp}
          ios={earthOutline}
        />
      }
    </div>

export default NewsItemBar
