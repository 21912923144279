import { createContext } from 'preact'
import { useContext, useMemo } from 'preact/hooks'
import { SWRConfig } from 'swr'
import type { SWRConfigurationWithProvider } from '../types'
import { useCacheProvider } from '@piotr-cz/swr-idb-cache'

import type { TImage } from '../../models/api'
import type { Preset } from '../../constants/asset'
import createFetchers from './fetcher'
import ApiStorageHandler from './StorageHandler'

type TApiContext = {
  baseUrl: string,
  accessToken: string,
  config: SWRConfigurationWithProvider,
  assetFetcher: (asset: TImage, preset?: Preset) => Promise<Blob>,
}

/**
 * API configuration context object
 */
const DirectusApiContext = createContext<TApiContext | null>(null)

/**
 * API Configuration hook
 */
export function useApi(): TApiContext {
  const value = useContext(DirectusApiContext)

  if (!value) {
    throw new Error('API Context has not been provided')
  }

  return value
}

/**
 * API Provider
 */
export const ApiProvider: preact.FunctionComponent<{
  baseUrl: string,
  accessToken: string,
  fallback?: JSX.Element,
}> = ({
  baseUrl,
  accessToken,
  fallback = null,
  children,
}) => {
  const provider = useCacheProvider({
    dbName: '@lokalniej/client',
    storeName: 'swr-cache',
    storageHandler: useMemo(() => new ApiStorageHandler(), []),
  })

  if (!provider) {
    return fallback
  }

  const {
    swr: fetcher,
    asset: assetFetcher,
  } = useMemo(() => createFetchers(baseUrl, accessToken),
    [baseUrl, accessToken]
  )

  const config: SWRConfigurationWithProvider = {
    fetcher,
    provider,
  }

  return (
    <DirectusApiContext.Provider value={{
      baseUrl,
      accessToken,
      config,
      assetFetcher,
    }}>
      <SWRConfig value={{ provider }}>
        {children as JSX.Element}
      </SWRConfig>
    </DirectusApiContext.Provider>
  )
}
