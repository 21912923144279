import { isPlatform } from '@ionic/react'

/**
 * ResizeObserver for react-virtuoso and Safari < 13.1
 * @link https://github.com/petyosi/react-virtuoso/blob/v4.0.3/README.md#browser-support
 */
/*
import ResizeObserverPolyfill from 'resize-observer-polyfill'

if (window && !window.ResizeObserver) {
  window.ResizeObserver = ResizeObserverPolyfill
}
*/

/**
 * On Apple mobile devices add the proprietary app icon and splashscreen markup.
 * Note: Cannot test on Windows as script checks if navigator.vendor is Apple
 * @link https://web.dev/learn/pwa/enhancements/#splash-screens
 * @link https://github.com/GoogleChromeLabs/pwacompat
 */
if (
  // iOS
  isPlatform('ios') &&
  // Not pwa installed
  !isPlatform('pwa') &&
  // Not native
  !isPlatform('hybrid')
) {
  window.addEventListener('load', () => {
    // Make sure manifest link is available (production)
    if (!document.querySelector('link[rel="manifest"]')) {
      return
    }

    const scriptElement = document.createElement('script')

    scriptElement.async = true
    scriptElement.src = 'https://unpkg.com/pwacompat'
    scriptElement.setAttribute('crossorigin', 'anonymous')

    document.head.appendChild(scriptElement)
  })
}
