import AnchorComponent from './Anchor'
import ImageComponent from './Image'

/**
 * [Custom Elements via Components]{@link https://github.com/developit/preact-markup#custom-elements-via-components}
 */
export default {
  a: AnchorComponent,
  img: ImageComponent,
}
