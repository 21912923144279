import { getApiUrl } from './helpers'
import { useApi } from '../../../data/api'

import './Anchor.css'

/**
 * Makrdown Anchor component
 * ```md
 * [text](href "title")
 * ```
 */
const AnchorComponent: preact.FunctionComponent<{
  href: string,
  children: string[],
  title?: string,
}> = ({
  href,
  children,
  title = undefined,
}) => {
  const {
    baseUrl,
    accessToken,
  } = useApi()

  const apiUrl = getApiUrl(href, baseUrl)

  // Set access token on API Asset
  if (apiUrl) {
    apiUrl.searchParams.set('access_token', accessToken)
  }

  return (
    <a
      href={apiUrl?.href ?? href}
      title={title}
      target="_blank"
      rel={apiUrl ? undefined : 'nofollow'}
    >
      {children}
    </a>
  )
}

export default AnchorComponent
