import type { SWRConfiguration, KeyedMutator } from 'swr'
import useSWR from 'swr'

import type { Fields, FieldsInterface } from './types'
import { useApi } from './ApiContext'
import { TransferError } from '../../utils/Error'

type FieldsResponse<Schema extends FieldsInterface> = {
  data: Fields<Schema>,
}

const fallbackData = {
  data: null,
}

/**
 * Generic collection fields list
 * @link https://docs.directus.io/reference/system/fields/#list-fields-in-collection
 */
export default function useFields<Schema extends FieldsInterface> (
  resource: string,
  shouldFetch: boolean = true,
  customConfig?: SWRConfiguration<FieldsResponse<Schema>, TransferError>
): {
  data: Fields<Schema> | null,
  error: TransferError | undefined,
  isLoading: boolean,
  mutate: KeyedMutator<FieldsResponse<Schema>>,
} {
  const path = `fields/${resource}`
  const { config } = useApi()

  const {
    data,
    error,
    mutate,
  } = useSWR<FieldsResponse<Schema>, TransferError>(shouldFetch ? { path } : null, {
    fallbackData: undefined,
    ...config,
    ...customConfig,
  })

  return {
    data: data ? data.data : fallbackData.data,
    error: shouldFetch && !data ? error : undefined,
    isLoading: shouldFetch && !data && !error,
    mutate,
  }
}
