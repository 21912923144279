import './Tags.css'

/**
 * Tags component
 */
const Tags: preact.FunctionComponent<{
  /** Available items */
  items: string[] | null,
}> = ({
  items,
}) =>
  <ul className="m1-tags-list">
    {items?.map(item =>
      <li
        key={item}
        className={`m1-tag m1-tag--${item}`}
      >
        {item}
      </li>
    )}
  </ul>

export default Tags
