import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar
} from '@ionic/react'

/**
 * Simple modal component
 */
const Modal: preact.FunctionComponent<{
  isOpen: boolean,
  animated?: boolean,
  canDismiss?: boolean,
  title?: string,
  onClose: () => void,
}> = ({
  isOpen,
  animated = true,
  canDismiss = true,
  title = undefined,
  onClose,
  children,
}) =>
  <IonModal
    isOpen={isOpen}
    animated={animated}
    canDismiss={canDismiss}
    onIonModalDidDismiss={onClose}
  >
    <IonHeader>
      <IonToolbar>
        {title &&
          <IonTitle>{title}</IonTitle>
        }
        <IonButtons slot="end">
          <IonButton
            onClick={onClose}
            disabled={!canDismiss}
          >
            {'Zamknij'}
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    <IonContent className="ion-padding m1-padding-small">
      {children as React.ReactNode}
    </IonContent>
  </IonModal>

export default Modal
