import { useMemo } from 'preact/hooks'
import { useRouteMatch } from 'react-router'
import {
  IonBackButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
} from '@ionic/react'

import type { OrganisationPageProps } from '../../contexts/organisation'
import type { TNewsSchema } from '../../models/api'
import type { TNews } from '../../models'

import { routes, generatePath } from '../../navigation'
import { useItem } from '../../data/api'
import useIonViewVisibility from '../../hooks/useIonViewVisibility'

import Page from '../../components/Page'
import TransferErrorAlert from '../../components/TransferErrorAlert'
import NewsItemImage from '../../components/NewsItemImage'
import DateTime from '../../components/DateTime'
import NewsItemBar from '../../components/NewsItemBar'
import Markdown from '../../components/Markdown'
import { ReactComponent as NewsIcon } from '../../images/pages/news.svg'

import '../NewsItem/News.css'
import './NewsItemPage.css'

type NewsItemPageProps = OrganisationPageProps & {
  newsItemId: string,
}

/**
 * News item page
 */
const NewsItemPage: preact.FunctionComponent = () => {
  const { params } = useRouteMatch<NewsItemPageProps>()

  const newsItemId = Number.parseInt(params.newsItemId)

  const isVisble = useIonViewVisibility()

  const {
    data: newsItemApi,
    error: newsItemError,
    isLoading: isLoadingNewsItem,
    mutate: newsItemMutate,
  } = useItem<TNewsSchema>(`news/${newsItemId}`, {
    fields: ['*', 'image.*'],
    // Here for GC
    // Note: Filtering only by organisation slug may result in Forbidden error when news organisation is null
    //       Need to: Update API, then update client
    filter: {
      _or: [
        { organisation: { slug: params.organisationSlug }},
        { organisation: { _null: true }},
      ],
    }
  }, isVisble)

  // Decorate
  const newsItem = useMemo(() =>
    newsItemApi && ({
      ...newsItemApi,
      date_published: new Date(newsItemApi.date_published),
    }) as TNews,
    [newsItemApi]
  )

  return (
    <Page
      screenName="NewsItem"
      toolbarPrimaryButtons={
        <IonBackButton defaultHref={generatePath(routes.newsList, { organisationSlug: params.organisationSlug })} />
      }
      theme={{
        name: 'news',
        title: 'Aktualności',
        icon: NewsIcon,
      }}
      isLoading={isLoadingNewsItem}
    >
      {newsItem &&
        <IonCard className="m1-news-item">
          <IonCardHeader className="m1-news-item__header">
            {/** Tags and global news indicator */}
            <NewsItemBar newsItem={newsItem} />
            <IonCardTitle className="m1-news-item__title">
              {/** Title */}
              {newsItem.title}
            </IonCardTitle>
            <IonCardSubtitle className="m1-news-item__date">
              {/** Date */}
              <DateTime
                date={newsItem.date_published}
                format="date"
              />
            </IonCardSubtitle>
          </IonCardHeader>
          {/** Content */}
          <IonCardContent className="m1-news-item__content">
            {/** Image */}
            {newsItem.image &&
              <NewsItemImage
                asset={newsItem.image}
                className="ion-margin-bottom"
                showMeta={true}
              />
            }

            {/** Text */}
            <Markdown markdown={newsItem.text} />

            {/** Source */}
            {newsItem.source &&
              <div className="m1-news-item__source">
                {`Źródło: ${newsItem.source}`}
              </div>
            }
          </IonCardContent>
        </IonCard>
      }

      {/** Error diialog */}
      {newsItemError &&
        <TransferErrorAlert
          error={newsItemError}
          mutate={newsItemMutate}
        />
      }
    </Page>
  )
}

export default NewsItemPage
