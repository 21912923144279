import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
} from '@ionic/react'
import clsx from 'clsx'

import { useOrganisation } from '../../contexts/organisation'
import { useFirebaseAnalytics } from '../../contexts/firebase-analytics'

import LoadingOverlay from '../LoadingOverlay'

import MenuIconString from './images/menu-icon.svg'

import './Page.css'

/**
 * Generic page component
 * with menu button
 */
const Page: preact.FunctionComponent<{
  screenName?: string,
  theme?: {
    /** Name */
    name: string,
    /** Page subtitle */
    title: string,
    /** Page icon */
    icon: preact.FunctionComponent<JSX.SVGAttributes<SVGSVGElement> & { title?: string }>,
  },
  /** Primary slot buttons */
  toolbarPrimaryButtons?: JSX.Element,
  /** Loading overlay switch */
  isLoading?: boolean,
  /** Do not use ion-content component to wrap children, use with modals */
  wrapContent?: boolean,
  scrollLock?: boolean,
}> = ({
  screenName = undefined,
  theme = undefined,
  toolbarPrimaryButtons = undefined,
  isLoading = false,
  wrapContent = true,
  scrollLock = false,
  children,
}) => {
  const organisation = useOrganisation()
  const { setCurrentScreen: setFACurrentScreen } = useFirebaseAnalytics()

  useIonViewDidEnter(() => {
    if (screenName) {
      setFACurrentScreen(screenName)
    }
  })

  return (
    <IonPage className={clsx('m1-page', theme && `m1-page--${theme.name}`)}>
      {organisation &&
        <IonHeader>
          {/** Primary toolbar */}
          <IonToolbar>
            {/** Menu button in start slot */}
            <IonButtons slot="start">
              <IonMenuButton>
                <IonIcon icon={MenuIconString} />
              </IonMenuButton>
            </IonButtons>
            {/** Additional buttons in primary slot */}
            {toolbarPrimaryButtons &&
              <IonButtons slot="primary">
                {toolbarPrimaryButtons}
              </IonButtons>
            }
            {/** Organisation name */}
            <IonTitle>
              {organisation.name}
            </IonTitle>
          </IonToolbar>
          {/** Secondary toolbar */}
          {theme &&
            <IonToolbar className="m1-toolbar-secondary">
              <IonTitle>
                {theme.title}
              </IonTitle>
              <theme.icon
                slot="end"
                className="m1-secondary-icon"
              />
            </IonToolbar>
          }
        </IonHeader>
      }
      {wrapContent
        ? <IonContent scrollY={!scrollLock}>
            {children as JSX.Element}
          </IonContent>
        : children as JSX.Element
      }
      <LoadingOverlay isOpen={isLoading} />
    </IonPage>
  )
}

export default Page
