import { useState, useEffect, useRef } from 'preact/hooks'
import clsx from 'clsx'
import {
  IonButton,
  IonIcon,
} from '@ionic/react'
import {
  reloadOutline,
  reloadSharp,
} from 'ionicons/icons'

import type { TImage } from '../../models/api'
import type { Preset } from '../../constants/asset'
import { type TransferError, ConnectError } from '../../utils/Error'
import { useApi } from '../../data/api'

import './AppImage.css'

interface ApiImageProps extends JSX.HTMLAttributes<HTMLImageElement> {
  /** Directus Asset */
  asset: TImage,
  /** Directus preset name */
  preset?: Preset,
  /** Ionic slot */
  slot?: string,
  /** Wrapper className */
  wrapperClassName?: string,
}

/**
 * API Image component
 * @see https://docs.directus.io/reference/files.html
 */
const ApiImage: preact.FunctionComponent<ApiImageProps> = ({
  asset,
  preset,
  slot,
  wrapperClassName,
  alt,
  children,
  ...attrs
}) => {
  const [ src, setSrc ] = useState<string | null>(null)

  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ error, setError ] = useState<TransferError | null>(null)

  const isMountedRef = useRef<boolean>(false)

  // Create asset URL including access key
  const { assetFetcher } = useApi()

  useEffect(() => {
    isMountedRef.current = true
    return () => isMountedRef.current = false
  }, [])

  // Fetch on mount
  useEffect(() => {
    fetchImage()
  }, [asset])

  // Remoke on object url change
  useEffect(() =>
    () =>
      src &&
      src.startsWith('blob:') &&
      URL.revokeObjectURL(src),
    [src]
  )

  /**
   * Handle reload button click
   */
  const handleReloadButtonClick: React.MouseEventHandler = event => {
    // Prevent navigation ie. when used inside cards
    event.preventDefault()
    event.stopPropagation()
    fetchImage()
  }

  return (
    <div
      className={clsx(['m1-api-image', wrapperClassName], {
        'm1-api-image--loading': isLoading,
        'm1-api-image--error': error,
      })}
      slot={slot}
    >
      {src &&
        <img
          alt={asset.title ?? undefined}
          height={!preset && asset.height || undefined}
          width={!preset && asset.width || undefined}
          {...attrs}
          src={src}
        />
      }
      {error instanceof ConnectError &&
        <IonButton
          className="m1-api-image__child m1-api-image__reload-button"
          color="warning"
          fill="clear"
          shape="round"
          onClick={handleReloadButtonClick}
        >
          <IonIcon
            slot="icon-only"
            md={reloadSharp}
            ios={reloadOutline}
          />
        </IonButton>
      }
    </div>
  )

  /**
   * Fetch image and update state
   */
  async function fetchImage(): Promise<void> {
    setIsLoading(true)
    setError(null)

    let responseBlob: Blob

    try {
      responseBlob = await assetFetcher(asset, preset)
    } catch (transferError) {
      isMountedRef.current && setError(transferError as TransferError)
      return
    } finally {
      isMountedRef.current && setIsLoading(false)
    }

    // Set source as object url
    const objectUrl = URL.createObjectURL(responseBlob)

    isMountedRef.current && setSrc(objectUrl)
  }
}

export default ApiImage
