import { IonLoading } from '@ionic/react'

const LoadingOverlay: preact.FunctionComponent<{
  isOpen: boolean,
}> = ({
  isOpen,
}) =>
  <IonLoading
    isOpen={isOpen}
    message="Wczytuję dane"
  />

export default (LoadingOverlay)
