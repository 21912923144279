/**
 * Action creators
 */
import type { IOrganisationSlug } from './types'

import TYPE from './action-types'

/**
 * Set organisation
 */
export const set = (organisationSlug: IOrganisationSlug) => ({
  type: TYPE.SET,
  payload: organisationSlug,
})

export type IOrganisationAction =
  ReturnType<typeof set>
