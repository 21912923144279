import PreactMarkdown from 'preact-markdown'
import type { PreactMarkupProps } from 'preact-markdown'

import type { marked } from 'marked'
import clsx from 'clsx'

import components from './elements'

import './Markdown.css'

const markupOptions: PreactMarkupProps = {
  wrap: false,
  // Note: May use component factory
  components,
}

const markedOptions: marked.MarkedOptions = {
  headerIds: false,
  langPrefix: 'm1-markup__language-',
  silent: true,
}

/**
 * Wrapper for Preact Markdown component
 */
const Markdown: preact.FunctionComponent<{
  /** Input text */
  markdown: string,
  /** Wrapper class name */
  className?: string,
}> = ({
  markdown,
  className,
}) => {
  return (
    <div className={clsx(['m1-markup', className])}>
      <PreactMarkdown
        markdown={markdown}
        markupOpts={markupOptions}
        markedOpts={markedOptions}
      />
    </div>
  )
}

export default Markdown
