import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonLabel,
  IonSkeletonText,
} from '@ionic/react'

import type {
  IStationSensor,
  IStationSensorWithNorm,
  TCurrentNorm,
} from '../../data/syngeos/types'

import type { TAirQualityStation } from '../../models'
import { SENSOR_TYPE } from '../../data/syngeos'

import { useStation } from '../../data/syngeos'
import DateTime from '../DateTime'

import './AirQualityStation.css'

/**
 * Syngeos Air Quality station card
 */
const AirQualityStation: preact.FunctionComponent<{
  airQualityStation: TAirQualityStation,
  sensorTypes?: SENSOR_TYPE[],
}> = ({
  airQualityStation,
  sensorTypes = [
    SENSOR_TYPE.PM2_5,
    SENSOR_TYPE.PM10,
    SENSOR_TYPE.TEMPERATURE,
    SENSOR_TYPE.HUMIDITY,
  ],
}) => {
  const {
    data: syngeosStation,
    error,
    isValidating,
    mutate,
  } = useStation(airQualityStation.station_id)

  // Error
  if (error) {
    return (
      <IonCard className="m1-aqstation">
        <IonCardHeader>
          <IonLabel color="danger">
            {'Brak dostępu do sieci'}
          </IonLabel>
        </IonCardHeader>
        <IonCardContent>
          <IonButton
            disabled={isValidating}
            onClick={() => mutate()}
          >
            {'Ponów'}
          </IonButton>
        </IonCardContent>
      </IonCard>
    )
  }

  // Loading
  if (!syngeosStation) {
    return (
      <IonCard className="m1-aqstation">
        <IonCardHeader>
          <IonCardTitle className="m1-aqstation__title">
            <IonSkeletonText style={{ width: '15em' }} />
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          {sensorTypes.map(sensorType =>
            <div className="m1-reading">
              <div className="m1-reading__meta">
                <p className="m1-reading__sensor-type">
                  <IonSkeletonText style={{ width: '8em'}} />
                </p>
              </div>
              <div className="m1-reading__data">
                <span className="m1-reading__value">
                  <IonSkeletonText style={{ width: '3em'}} />
                </span>
                <span className="m1-reading__unit">
                  <IonSkeletonText style={{ width: '2em'}} />
                </span>
              </div>
            </div>
          )}
        </IonCardContent>
      </IonCard>
    )
  }

  const sensors = syngeosStation.sensors
    .filter(sensor => sensorTypes.includes(sensor.name))
    .sort((sensorA, sensorB) => sensorTypes.indexOf(sensorA.name) - sensorTypes.indexOf(sensorB.name))

  return (
    <IonCard className="m1-aqstation">
      <IonCardHeader>
        <IonCardTitle className="m1-aqstation__title">
          {syngeosStation.address &&
            <span className="m1-aqstation__address">
              {syngeosStation.address}
            </span>
          }
          <span className="m1-aqstation__city">
            {syngeosStation.city}
          </span>
        </IonCardTitle>
      </IonCardHeader>
      <IonCardContent className="m1-aqstation__content">
        {sensors.map(sensor =>
          isFirstWithNorm(sensor, sensors)
            ? // With norm
              <div className="m1-reading m1-reading--with-norm">
                <div className="m1-reading__meta">
                  {/** Label */}
                  <p className="m1-reading__norm-label">
                    {sensor.data[0]
                      ? getNormLabel(sensor.data[0].current_norm)
                      : 'Brak danych'
                    }
                  </p>
                  <p>
                    {'Jakość Powietrza'}
                  </p>

                  {/** Reading Date */}
                  {/*
                  {sensor.data[0] &&
                    <p className="m1-reading__date">
                      <DateTime
                        date={new Date(sensor.data[0].read_at)}
                        format="date"
                      />
                    </p>
                  }
                  */}
                </div>

                {/** Indicator */}
                <div className={`m1-reading__data m1-reading__data--${sensor.data[0]?.current_norm ?? 'unknown'}`}>
                  <span className="m1-reading__value">
                    {sensor.data[0]
                      ? sensor.data[0].value.toFixed(0)
                      : '?'
                    }
                  </span>
                  <span className="m1-reading__symbol">
                    {getSensorTypeSymbol(sensor.name)}
                  </span>
                </div>
              </div>
            : // Basic
              <div className="m1-reading">
                <div className="m1-reading__meta">
                  <p className="m1-reading__sensor-type">
                    {getSensorTypeSymbol(sensor.name)}
                  </p>
                </div>
                <div className="m1-reading__data">
                  <span className="m1-reading__value">
                    {sensor.data[0]
                      ? sensor.data[0].value.toFixed(0)
                      : '?'
                    }
                  </span>
                  <span className="m1-reading__unit">
                    {sensor.unit}
                  </span>
                </div>
              </div>
        )}
      </IonCardContent>
    </IonCard>
  )
}

export default AirQualityStation

/**
 * Translate norm grade label
 * TODO: i18n
 */
function getNormLabel(currentNorm: TCurrentNorm): string {
  switch (currentNorm) {
    case 'grade-a': return 'Bardzo dobra'
    case 'grade-b': return 'Dobra'
    case 'grade-c': return 'Umiarkowana'
    case 'grade-d': return 'Dostateczna'
    case 'grade-e': return 'Zła'
    case 'grade-f': return 'Bardzo zła'
  }
}

/**
 * Get symbol element
 * TODO: i18n
 */
function getSensorTypeSymbol(sensorType: SENSOR_TYPE): JSX.Element | string {
  switch (sensorType) {
    case SENSOR_TYPE.PM2_5:        return <>PM<sub>2.5</sub></>
    case SENSOR_TYPE.PM10:         return <>PM<sub>10</sub></>
    case SENSOR_TYPE.PM1:          return <>PM<sub>1</sub></>
    case SENSOR_TYPE.TEMPERATURE:  return 'Temperatura'
    case SENSOR_TYPE.AIR_PRESSURE: return 'Ciścnienie'
    default:                       return sensorType
  }
}

/**
 * First with norm guard
 */
function isFirstWithNorm (stationSensor: IStationSensor, stationSensors: IStationSensor[]): stationSensor is IStationSensorWithNorm {
  return (
    isStationSensorWithNorm(stationSensor) &&
    stationSensor === stationSensors.find(isStationSensorWithNorm)
  )
}

/**
 * Station sensor with norm guard
 */
function isStationSensorWithNorm (stationSensor: IStationSensor): stationSensor is IStationSensorWithNorm {
  return 'norm' in stationSensor
}
