import type { IAction } from '../types'
import type { IOrganisationsPreferences } from './types'
import TYPE from './action-types'

export default function reducer (state: IOrganisationsPreferences, action: IAction): IOrganisationsPreferences {
  switch (action.type) {
    case TYPE.SET_GARBAGE_ZONE:
      return state.find(orgPrefs => orgPrefs.organisation === action.payload.organisation)
        ? // Update
          state.map(orgPrefs => ({
            ...orgPrefs,
            garbageZone: orgPrefs.organisation === action.payload.organisation
              ? action.payload.garbageZone
              : orgPrefs.garbageZone
          }))
        : // Create
          [ ...state, {
            organisation: action.payload.organisation,
            garbageZone: action.payload.garbageZone,
          }]
  }

  return state
}
