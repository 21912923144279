/**
 * Firebase Analytics provider
 *
 * [Firebase Web API Reference]{@link https://firebase.google.com/docs/reference/js}
 * [Google Analytics Docs]{@link https://firebase.google.com/docs/analytics}
 * [@capacitor-firebase/analytics Docs]{@link https://github.com/capawesome-team/capacitor-firebase/tree/main/packages/analytics}
 */
import { createContext } from 'preact'
import { useContext, useMemo } from 'preact/hooks'
import { isPlatform } from '@ionic/core'
import { FirebaseAnalytics } from '@capacitor-firebase/analytics'
import { initializeApp } from 'firebase/app'
import { getAnalytics, setDefaultEventParameters } from 'firebase/analytics'

import firebaseWebConfig from '../config/firebase-web.json'

/**
 * API
 */
type TFirebaseAnalyticsContext = {
  setCurrentScreen: (screenName: string) => Promise<void>,
  setOrganisation: (organisationSlug: string | null) => void,
}

/**
 * Context object
 */
const FirebaseAnalyticsContext = createContext<TFirebaseAnalyticsContext | null>(null)

/**
 * Hook
 */
export function useFirebaseAnalytics(): TFirebaseAnalyticsContext {
  const value = useContext(FirebaseAnalyticsContext)

  if (!value) {
    throw new Error('Firebase Analytics Context has not been provided')
  }

  return value
}

/**
 * Provider
 *
 * Configure Firebase Analytics and Google Analytics
 * Analytics Data collection is temporarily disabled at start and may be enabled by
 * - mobile app: env variable flag (production)
 * - web app: after users consent
 * @link https://firebase.google.com/docs/analytics/configure-data-collection
 *
 * @TODO Add cookies consent mechanism for web
 * @param {boolean} envEnabled - State defined at build time
 */
export const FirebaseAnalyticsProvider: preact.FunctionComponent<{
  envEnabled: boolean
}> = ({
  envEnabled = true,
  children,
}) => {
  // Use useMemo to run before render
  useMemo(() => {
    // Initialize web
    if (!isPlatform('hybrid') && envEnabled) {
      // Initialize Firebase
      const firebaseWebApp = initializeApp(firebaseWebConfig, { automaticDataCollectionEnabled: false })
      // Initialize Analytics
      getAnalytics(firebaseWebApp)
    }

    // Enable if possible
    // Note: Rendering should be stop until promise is resolved, however in practise API methods are used that
    if (envEnabled) {
      return FirebaseAnalytics.setEnabled({ enabled: true })
    }
  }, [])

  return (
    <FirebaseAnalyticsContext.Provider value={{
      setCurrentScreen,
      setOrganisation,
    }}>
      {children}
    </FirebaseAnalyticsContext.Provider>
  )

  /**
   * Set current screen name
   * Note: The page_view event should be tracked by default for web
   * Note: This includes workaround for web (logEvent screen_view)
   *
   * @link https://firebase.google.com/docs/analytics/screenviews#manually_track_screens
   */
  async function setCurrentScreen (screenName: string): Promise<void> {
    if (envEnabled) {
      return FirebaseAnalytics.setCurrentScreen({ screenName })
    }
  }

  /**
   * Set current organisation
   *
   * @link https://firebase.google.com/docs/reference/js/analytics.md#setdefaulteventparameters
   * [GA4: Custom dimensions and metrics]{@link https://support.google.com/firebase/answer/10075209}
   * [Dimensions and metrics]{@link https://support.google.com/analytics/topic/2709827}
   */
  function setOrganisation (organisationSlug: string | null): void {
    if (envEnabled) {
      setDefaultEventParameters({ organisation: organisationSlug })
    }
  }
}
