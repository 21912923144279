import { useEffect, useState, useMemo, useCallback } from 'preact/hooks'
import { useRouteMatch } from 'react-router'
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonButton,
  IonAccordion,
  IonAccordionGroup,
} from '@ionic/react'
import {
  chevronDown,
  createOutline,
} from 'ionicons/icons'
import type { KeyedMutator } from 'swr'

import type { OrganisationPageProps } from '../../contexts/organisation'
import type {
  TGarbageCollectionSchema,
  TGarbageTypeSchema,
  TGarbageZoneSchema,
} from '../../models/api'
import type { TGarbageCollection } from '../../models'
import type {
  TCalendarRange,
  TIndicatorColorResolver,
} from '../../components/Calendar'

import { useItems } from '../../data/api'
import { useStore } from '../../state'
import { setGarbageZone } from '../../state/orgs-prefs'
import useIonViewVisibility from '../../hooks/useIonViewVisibility'

import {
  toSqlDate,
  isSameDay,
} from '../../utils/date'

import Page from '../../components/Page'
import Modal from '../../components/Modal'
import Markdown from '../../components/Markdown'
import TransferErrorAlert from '../../components/TransferErrorAlert'
import GarbageTypeIndicator from '../../components/GarbageTypeIndicator'
import Calendar from '../../components/Calendar'
import GarbageZonePicker from '../../components/GarbageZonePicker'
import { ReactComponent as GarbageCollectionsIcon } from '../../images/pages/garbage-collections.svg'

import './GarbageCollectionsPage.css'

/**
 * Garbage collection schedule page
 */
const GarbageCollectionsPage: preact.FunctionComponent = () => {
  const { params: { organisationSlug } } = useRouteMatch<OrganisationPageProps>()

  const today: Date = useMemo(() =>
    new Date(new Date().setHours(0, 0, 0, 0)),
    []
  )

  const [ referenceDate, setReferenceDate ] = useState<Date>(() => today)
  const [ selectedDate, setSelectedDate ] = useState<Date|null>(null)

  const isVisible = useIonViewVisibility()

  // Get organisation preferences
  const selectedGarbageZone = useStore(useCallback(state =>
    state.orgsPrefs
      .find(orgPrefs => orgPrefs.organisation === organisationSlug)
      ?.garbageZone ?? null,
    [organisationSlug])
  )

  const [ showGarbageZonePicker, setShowGarbageZonePicker ] = useState<boolean>(() =>
    selectedGarbageZone === null
  )

  const dispatch = useStore(state => state.dispatch)

  // Compute calendar range for 1 month
  const calendarRange: TCalendarRange = useMemo(() => ({
    // First day of current month
    start: new Date(referenceDate.getFullYear(), referenceDate.getMonth() + 0, 1),
    // First day of next month
    end:   new Date(referenceDate.getFullYear(), referenceDate.getMonth() + 1, 1),
  }), [referenceDate])

  // Fetch available garbage types
  const {
    data: garbageTypes,
    error: garbageTypesError,
    isLoading: isLoadingGarbageTypes,
    mutate: garbageTypesMutate,
  } = useItems<TGarbageTypeSchema>('garbage_types', {
    fields: ['*'],
    filter: { organisation: { slug: organisationSlug } },
    sort: ['sort'],
    limit: -1,
  }, isVisible)

  // Fetch available garbage zones
  const {
    data: garbageZones,
    error: garbageZonesError,
    mutate: garbageZonesMutate,
    isLoading: isLoadingGarbageZones,
  } = useItems<TGarbageZoneSchema>('garbage_zones', {
    fields: ['*'],
    filter: { organisation: { slug: organisationSlug } },
    sort: ['sort'],
    limit: -1,
  }, isVisible)

  // Fetch garbage collections
  const {
    data: garbageCollectionsApi,
    error: garbageCollectionsError,
    isLoading: isLoadingGcs,
    mutate: garbageCollectionsMutate,
  } = useItems<TGarbageCollectionSchema>('garbage_collections', {
    fields: ['*'],
    filter: {
      _and: [
        // Note: May load all and filter on the fly
        { organisation: { slug: organisationSlug }},
        { garbage_type: undefined },
        { garbage_zone: selectedGarbageZone ? { id: selectedGarbageZone } : undefined },
        { date: { _gte: toSqlDate(calendarRange.start) } },
        { date: { _lt: toSqlDate(calendarRange.end) } },
      ],
    },
    sort: ['date', 'garbage_type'],
    limit: -1,
  }, isVisible && selectedGarbageZone !== null)

  // Decorate
  const garbageCollections = useMemo(() => garbageCollectionsApi.map(gc =>
    ({
      ...gc,
      date: new Date(gc.date),
    }) as TGarbageCollection),
    [garbageCollectionsApi]
  )

  /**
   * Day <-> Garbage collections hash table
   */
  const daysData = useMemo(() => {
    const daysData = new Map<Date, TGarbageCollection[]>()

    // Mote: Do not iterate with 24h as it breaks on daylight switch (2022-10-30)
    for (
      let day = calendarRange.start;
      day < calendarRange.end;
      day = new Date(day.getFullYear(), day.getMonth(), day.getDate() + 1)
    ) {
      daysData.set(
        day,
        garbageCollections.filter(gc => isSameDay(gc.date, day))
      )
    }

    return daysData
  }, [calendarRange.start, calendarRange.end, garbageCollections])

  /**
   * Get indicator color from garbage type color
   */
  const getIndicatorColor: TIndicatorColorResolver<TGarbageCollection> = useCallback(
    (garbageCollection: TGarbageCollection): string | undefined => {
      const garbageType = garbageTypes
        .find(garbageType => garbageType.id === garbageCollection.garbage_type)

      return garbageType?.color ?? undefined
    },
    [garbageTypes]
  )

  /**
   * Reset state of selected date on month switching
   */
  useEffect(() => {
    setSelectedDate(null)
  }, [referenceDate])

  /**
   * Handle date select toggle
   */
  const handleDateSelect = (date: Date): void =>
    setSelectedDate(date === selectedDate ? null : date)

  /**
   * Handle fetch error retry
   */
  const handleErrorRetry = (): Promise<any> => {
    const mutators: KeyedMutator<any>[] = []

    if (garbageTypesError) mutators.push(garbageTypesMutate)
    if (garbageZonesError) mutators.push(garbageZonesMutate)
    if (garbageCollectionsError) mutators.push(garbageCollectionsMutate)

    return Promise.all(mutators.map(m => m()))
  }

  return (
    <Page
      screenName="GarbageCollections"
      toolbarPrimaryButtons={
        <IonButton
          disabled={showGarbageZonePicker}
          onClick={() => setShowGarbageZonePicker(true)}
        >
          <IonIcon
            slot="icon-only"
            icon={createOutline}
          />
        </IonButton>
      }
      theme={{
        name: 'garbage-collections',
        title: 'Odbiór śmieci',
        icon: GarbageCollectionsIcon,
      }}
      isLoading={isLoadingGarbageTypes || isLoadingGarbageZones || isLoadingGcs}
      wrapContent={false}
    >
      <IonContent>
        {/** Calendar */}
        <Calendar
          today={today}
          referenceDate={referenceDate}
          selectedDate={selectedDate}
          calendarRange={calendarRange}
          daysData={daysData}
          setReferenceDate={setReferenceDate}
          handleDateSelect={handleDateSelect}
          getIndicatorColor={getIndicatorColor}
        />
        {/** Legend */}
        {garbageTypes.length !== 0 &&
          <IonCard className="m1-card">
            <IonCardHeader>
              <IonCardTitle className="m1-card__title">
                {'Rodzaj odpadu surowca'}
              </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <IonAccordionGroup multiple>
                {garbageTypes.map(garbageType =>
                  <IonAccordion
                    key={garbageType.id}
                    className="m1-garbage-type-accordion"
                    value={garbageType.id.toString()}
                    readonly={!garbageType.description}
                    disabled={selectedDate !== null && !daysData
                      .get(selectedDate)
                      ?.find(gc => gc.garbage_type === garbageType.id)
                    }
                    toggleIcon={chevronDown}
                  >
                    <IonItem
                      slot="header"
                      color="light"
                    >
                      <GarbageTypeIndicator
                        color={garbageType.color}
                        slot="start"
                      />
                      <IonLabel className="m1-label-full-text">
                        {garbageType.label}
                      </IonLabel>
                    </IonItem>
                    {garbageType.description &&
                      <div className="ion-padding" slot="content">
                        <Markdown markdown={garbageType.description} />
                      </div>
                    }
                  </IonAccordion>
                )}
              </IonAccordionGroup>
            </IonCardContent>
          </IonCard>
        }
      </IonContent>

      {/**
       * Zone picker
       * TODO: Close modal when navigating to other page or make it optional as ion-modal canDismiss somehow doesn't receive updates
       */}
      <Modal
        isOpen={showGarbageZonePicker && garbageZones.length !== 0}
        animated={isVisible}
        canDismiss={selectedGarbageZone !== null || !garbageZones.length}
        onClose={() => setShowGarbageZonePicker(false)}
      >
        <GarbageZonePicker
          selected={selectedGarbageZone}
          garbageZones={garbageZones}
          setGarbageZone={id => dispatch(setGarbageZone(organisationSlug, id))}
        />
      </Modal>

      {/** Error dialog */}
      {(garbageTypesError ?? garbageZonesError ?? garbageCollectionsError) &&
        <TransferErrorAlert
          error={(garbageTypesError ?? garbageZonesError ?? garbageCollectionsError)!}
          mutate={handleErrorRetry}
        />
      }
    </Page>
  )
}

export default GarbageCollectionsPage
