const  locale = 'pl-PL'

/**
 * TODO: Use smart format (hours for today)
 * @link https://github.com/directus/examples/blob/main/shared/utils/format-relative-time.js
 */
const DateTime: preact.FunctionComponent<{
  date: Date,
  format?: 'date' | 'time' | 'dateTime',
}> = ({
  date,
  format = 'dateTime'
}) =>
  <time dateTime={date.toISOString()}>
    {{
      'date':     date.toLocaleDateString(locale),
      'time':     date.toLocaleTimeString(locale),
      'dateTime': date.toLocaleString(locale),
    }[format]}
  </time>

export default DateTime
