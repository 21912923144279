import { ReactComponent as MadreOgrzewanieIcon }                   from './images/accordion/madre-ogrzewanie.svg'
import { ReactComponent as OdpowiedniaIloscZieleniIcon }           from './images/accordion/odpowiednia-ilosc-zieleni.svg'
import { ReactComponent as EdukacjaMlodegoPokoleniaIcon }          from './images/accordion/edukacja-mlodego-pokolenia.svg'
import { ReactComponent as ReagujNaZachowaniaWSwoimOtoczeniuIcon } from './images/accordion/reaguj-na-zachowania-w-swoim-otoczeniu.svg'
import { ReactComponent as WspierajSlabszychIcon }                 from './images/accordion/wspieraj-slabszych.svg'
import { ReactComponent as OczyszczaczePowietrzaIcon }             from './images/accordion/oczyszczacze-powietrza.svg'
import { ReactComponent as OgraniczJazdeSamochodemIcon }           from './images/accordion/ogranicz-jazde-samochodem.svg'
import { ReactComponent as PodrozujEkologicznieIcon }              from './images/accordion/podrozuj-ekologicznie.svg'
import { ReactComponent as KomunikacjaMiejskaIcon }                from './images/accordion/komunikacja-miejska.svg'

/**
 * Interactive point
 */
type TInteractivePoint = {
  /** Header */
  header: string,
  /** Icon */
  icon: preact.FunctionComponent<JSX.SVGAttributes<SVGSVGElement> & { title?: string }>,
  /** Content */
  content: string,
  /** Coordnates (top, left) in % */
  coords: [number, number],
}

const interactivePoints: TInteractivePoint[] = [
  {
    header: 'Mądre ogrzewanie',
    icon: MadreOgrzewanieIcon,
    content: 'Jak możesz zadbać o właściwą atmosferę w swojej okolicy?\n\nJeżeli tylko masz możliwość, podłącz budynek do sieci ciepłowniczej bądź gazowej. W pozostałych przypadkach wymień kocioł na model nowej generacji i nie zapominaj o wysokiej jakości opale (regularnie zlecaj przeglądy kominiarskie).\n\nOptymalnym wyborem jest inne, nowoczesne źródło ciepła.\n\nAby uzyskać maksymalną optymalizację kosztów ogrzewania i zadbać o środowisko, zaplanuj odpowiednią termomodernizację budynku – w ten sposób zredukujesz straty ciepła.',
    coords: [40, 59],
  },
  {
    header: 'Odpowiednia ilość zieleni',
    icon: OdpowiedniaIloscZieleniIcon,
    content: 'Drzewa i krzewy są niezbędnym elementem walki ze smogiem. Produkują tlen i pochłaniają część zanieczyszczeń. Rośliny są w stanie absorbować z powietrza frakcje gazowe oraz wychwytywać zanieczyszczenia pyłowe tzw. PM-y.\n\nNasadzenia pomiędzy chodnikami a jezdnią, tworzą dodatkową barierę dla zanieczyszczeń pochodzenia komunikacyjnego.\n\nDodatkowo zapewniają latem cień.',
    coords: [47, 85],
  },
  {
    header: 'Edukacja młodego pokolenia',
    icon: EdukacjaMlodegoPokoleniaIcon,
    content: 'Edukuj najmłodszych poprzez prawidłowe postawy na co dzień. Pozytywne nawyki, wypracowane u dzieci zaowocują czystym powietrzem w przyszłości. Rozmawiaj na temat ochrony powietrza z najbliższymi, tłumacz, pokazuj związki przyczynowo- skutkowe, dawaj dobry przykład.',
    coords: [52, 22],
  },
  {
    header: 'Reaguj na zachowania w swoim otoczeniu',
    icon: ReagujNaZachowaniaWSwoimOtoczeniuIcon,
    content: 'Osoby, które spalają odpady, szkodzą całej społeczności. Nie bój się rozmawiać i uświadamiać sąsiadów o znaczeniu jakości powietrza w życiu codziennym. W dyskusji możesz powołać się na miejscową uchwałę antysmogową, która obowiązuje wszystkich!',
    coords: [57, 70],
  },
  {
    header: 'Wspieraj słabszych w smogowe dni',
    icon: WspierajSlabszychIcon,
    content: 'Osoby starsze, chore, kobiety w ciąży czy dzieci – to grupa szczególnie narażona na negatywne skutki oddychania zanieczyszczonym powietrzem. Jeżeli znasz takie osoby i możesz im pomóc w dni smogowe, zaproponuj zrobienie zakupów, wyprowadzenie psa czy inne wsparcie, ograniczające konieczność ich przebywania na zewnątrz budynku.\n\nPrzekazuj im informacje, ogłaszane przez alerty smogowe! Osoby starsze, chore, kobiety w ciąży czy dzieci – to grupa szczególnie narażona na negatywne skutki oddychania zanieczyszczonym powietrzem. Jeżeli znasz takie osoby i możesz im pomóc w dni smogowe, zaproponuj zrobienie zakupów, wyprowadzenie psa czy inne wsparcie, ograniczające konieczność ich przebywania na zewnątrz budynku.\n\nPrzekazuj im informacje, ogłaszane przez alerty smogowe!',
    coords: [30, 75],
  },
  {
    header: 'Oczyszczacze powietrza',
    icon: OczyszczaczePowietrzaIcon,
    content: 'W dni o podwyższonych poziomach smogu używaj oczyszczacza powietrza i nie otwieraj okien. Jeżeli możesz, zaopatrz się w system wentylacji wnętrz z filtrami powietrza. Zadbaj o właściwą ilość zielonych roślin, naturalnie oczyszczających powietrze w Twoim domu.',
    coords: [26, 40],
  },
  {
    header: 'Ogranicz jazdę samochodem',
    icon: OgraniczJazdeSamochodemIcon,
    content: 'Wybieraj ekologiczne środki transportu. Dziel się przestrzenią w pojeździe (współdzielone przejazdy).Dbaj o stan techniczny samochodu – zlecaj regularne przeglądy.',
    coords: [59.5, 92],
  },
  {
    header: 'Podróżuj ekologicznie',
    icon: PodrozujEkologicznieIcon,
    content: 'Jazda na rowerze czy hulajnodze pomaga ograniczyć zanieczyszczenia oraz zbawiennie wpływają na zdrowie. Dbaj o zdrowie, aktywnie spędzając czas! Motywuj dzieci i bliskich do zmiany nawyków.',
    coords: [71, 64],
  },
  {
    header: 'Wybieraj komunikację miejską',
    icon: KomunikacjaMiejskaIcon,
    content: 'Jazda autobusem, tramwajem czy pociągiem wpływa pozytywnie na redukcję zanieczyszczeń, pochodzenia komunikacyjnego.',
    coords: [75, 34],
  },
]

export default interactivePoints
