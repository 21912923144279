export enum SENSOR_TYPE {
  PM2_5            = 'pm2_5',
  PM10             = 'pm10',
  SO2              = 'so2',
  NO2              = 'no2',
  CO               = 'co',
  O3               = 'o3',
  C6H6             = 'c6h6',
  PM1              = 'pm1',
  TEMPERATURE      = 'temperature',
  AIR_PRESSURE     = 'air_pressure',
  HUMIDITY         = 'humidity',
  CAQI             = 'caqi',
  CH2O             = 'ch2o',
  NOISE            = 'noise',
  SOIL_VWC         = 'soil_vwc',
  SOIL_TEMPERATURE = 'soil_temperature',
  SOIL_AP          = 'soil_ap',
  CO2              = 'co2',
  UV               = 'uv',
  LIGHT_INTENSITY  = 'light_intensity',
}
