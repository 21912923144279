import useSWR from 'swr'
import type { SWRConfiguration, KeyedMutator } from 'swr'

import type { IStation } from './types'

import { useApiConfiguration } from './ApiContext'
import { TransferError } from '../../utils/Error'

const apiRefreshInterval = 6 * 60 * 1e3
const fallbackData = null

/**
 * Fetch station
 */
export default function useStation(
  stationId: number,
  shouldFetch: boolean = true,
  customConfig?: SWRConfiguration<IStation, TransferError>,
): {
  data: IStation | null,
  error: TransferError | undefined,
  isLoading: boolean,
  isValidating: boolean,
  mutate: KeyedMutator<IStation>,
} {
  const path = `public/data/device/${stationId}`

  const config = useApiConfiguration()

  const { data, error, isValidating, mutate } = useSWR<IStation, TransferError>(shouldFetch ? path : null, {
    refreshInterval: apiRefreshInterval,
    dedupingInterval: apiRefreshInterval,
    fallbackData: undefined,
    ...config,
    ...customConfig,
  })

  return {
    data: data ?? fallbackData,
    error: shouldFetch && !data ? error : undefined,
    isLoading: shouldFetch && !data && !error,
    isValidating,
    mutate,
  }
}
