/**
 * Organisation object provider
 */
import { createContext } from 'preact'
import { useContext } from 'preact/hooks'
import { useRouteMatch } from 'react-router'

import type { TOrganisationSchema } from '../models/api'
import { useItemQuery } from '../data/api'
import { routes } from '../navigation'
import { useFirebaseAnalytics } from './firebase-analytics'

import NotFoundPage from '../pages/NotFound'

/**
 * API
 */
type TOrganisationContext = TOrganisationSchema | null

/**
 * Path property
 */
export type OrganisationPageProps = {
  organisationSlug: string,
}

/**
 * Context object
 */
const OrganisationContext = createContext<TOrganisationContext>(null)

/**
 * Hook
 */
export function useOrganisation(): TOrganisationContext {
  return useContext(OrganisationContext)
}

/**
 * Provider
 */
export const OrganisationProvider: preact.FunctionComponent<{
  fallback: JSX.Element,
}> = ({
  children,
  fallback,
}) => {
  // Resolve slug from common path
  const match = useRouteMatch<OrganisationPageProps>(routes.city)
  const organisationSlug = match?.params.organisationSlug ?? null

  const { setOrganisation: setGAOrganisationParam } = useFirebaseAnalytics()

  // Load data
  const {
    data: organisation,
    isLoading,
  } = useItemQuery<TOrganisationSchema>('organisations', {
    fields: ['*', 'logo.*'],
    filter: { slug: { _eq: organisationSlug } },
  }, organisationSlug !== null)

  // Set Firebase Analytics user property
  setGAOrganisationParam(organisationSlug)

  if (organisationSlug) {
    // Loading
    if (isLoading) {
      return fallback
    }

    // Not found
    // Note: May update global state
    if (!organisation) {
      return <NotFoundPage />
    }
  }

  return (
    <OrganisationContext.Provider value={organisation}>
      {children}
    </OrganisationContext.Provider>
  )
}
