/**
 * Image API presets
 * Note: Using presens not defined in API results in 400 (Bad Request) errors
 */
export enum Preset {
  // Vector
  OrganisationLogo = 'organisation-logo',
  // Any
  NewsImage = 'news-image',
  // Content Preset
  ContentImage = 'content-image',
}

/**
 * Asset types
 * @deprecated - Not used
 */
export enum Type {
  File = 'file',
  Image = 'image',
}
