import { useState, useMemo } from 'preact/hooks'
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
} from '@ionic/react'
import { useRouteMatch } from 'react-router'
import clsx from 'clsx'
import type { KeyedMutator } from 'swr'

import type { OrganisationPageProps } from '../../contexts/organisation'
import type { TNewsSchema } from '../../models/api'
import type { TNews } from '../../models'

import { useOrganisation } from '../../contexts/organisation'
import { useItems, useFields } from '../../data/api'
import useIonViewVisibility from '../../hooks/useIonViewVisibility'
import { routes, generatePath } from '../../navigation'

import Page from '../../components/Page'
import TransferErrorAlert from '../../components/TransferErrorAlert'
import DateTime from '../../components/DateTime'
import NewsItemImage from '../../components/NewsItemImage'
import { ButtonTags } from '../../components/Tags'
import NewsItemBar from '../../components/NewsItemBar'
import NoItems from '../../components/NoItems'
import { ReactComponent as NewsIcon } from '../../images/pages/news.svg'

import '../NewsItem/News.css'
import './NewsListPage.css'

/**
 * News items list page
 */
const NewsListPage: preact.FunctionComponent = () => {
  const { params: { organisationSlug } } = useRouteMatch<OrganisationPageProps>()
  const organisation = useOrganisation()

  const [ selectedTags, setSelectedTags ] = useState<string[]>([])

  const isVisible = useIonViewVisibility()

  // Create filter
  const filter = organisation?.hide_global_news
    // Local news
    ? { _or: [ { organisation: { slug: organisationSlug } } ] }
    // Local news + Global news
    : { _or: [ { organisation: { slug: organisationSlug } }, { organisation: { _null: true } } ] }

  const {
    data: news,
    error: newsError,
    isLoading: isLoadingNews,
    mutate: newsMutate,
  } = useItems<TNewsSchema>('news', {
    fields: ['*', 'image.*'],
    filter,
    sort: ['-date_published', 'title'],
    limit: 10,
  }, isVisible)

  // Load available tags
  // Note: When using `directus.items('directus_fields').readByQuery` filters don't work
  // TODO: Use M2M collection, as it's not possible to filter by tags. However will lose ability to use custom tags
  const {
    data: tagsData,
    error: tagsError,
    isLoading: isLoadingTags,
    mutate: tagsMutate,
  } = useFields<{collection: 'news', field: 'tags', options: { presets: string[] }}>('news/tags', isVisible)

  const tags = tagsData?.meta.options.presets ?? []

  // Decorate
  const newsItems = useMemo(() =>
    news.map(newsItem => ({
      ...newsItem,
      date_published: new Date(newsItem.date_published)
    }) as TNews),
    [news]
  )

  // Filter by tags
  const filteredNewsItems = useMemo(() =>
    selectedTags.length
      ? newsItems.filter(newsItem => newsItem.tags?.filter(tag => selectedTags.includes(tag)).length)
      : newsItems,
    [newsItems, selectedTags]
  )

  /**
   * Handle tag toggle
   */
  const handleTagToggle = (event: MouseEvent): void => {
    if (event.target instanceof HTMLButtonElement) {
      const tag = event.target.value
      const tags = selectedTags.includes(tag)
        ? selectedTags.filter(item => item !== tag)
        : [...selectedTags, tag]

      setSelectedTags(tags)
    }
  }

  /**
   * Handle fetch error retry
   */
  const handleErrorRetry = (): Promise<any> => {
    const mutators: KeyedMutator<any>[] = []

    if (tagsError) mutators.push(tagsMutate)
    if (newsError) mutators.push(newsMutate)

    return Promise.all(mutators.map(m => m()))
  }

  return (
    <Page
      screenName="NewsList"
      theme={{
        name: 'news',
        title: 'Aktualności',
        icon: NewsIcon,
      }}
      isLoading={isLoadingNews || isLoadingTags}
    >
      {/** Tags */}
      <ButtonTags
        items={tags}
        selected={selectedTags}
        onClick={handleTagToggle}
      />

      {filteredNewsItems.map((newsItem, index) =>
        <IonCard
          key={newsItem.id}
          routerLink={generatePath(routes.newsItem, {
            organisationSlug: organisationSlug,
            newsItemId: newsItem.id,
          })}
          className={clsx(['m1-news-list-item', 'm1-news-item'], { 'm1-news-list-item--lead': !index })}
        >
          <IonCardContent className="m1-news-list-item__content">
            {newsItem.image &&
              <NewsItemImage
                asset={newsItem.image}
                isListItem={index > 0}
              />
            }
            <IonCardHeader className="m1-news-list-item__header">
              {/** Tags and global news indicator */}
              <NewsItemBar newsItem={newsItem} />
              <IonCardTitle className="m1-news-item__title">
                {newsItem.title}
              </IonCardTitle>
              <IonCardSubtitle className="m1-news-item__date">
                <DateTime
                  date={newsItem.date_published}
                  format="date"
                />
              </IonCardSubtitle>
            </IonCardHeader>
          </IonCardContent>
        </IonCard>
      )}

      {!filteredNewsItems.length && !isLoadingNews &&
        <NoItems />
      }

      {/** Error dialog */}
      {(newsError ?? tagsError) &&
        <TransferErrorAlert
          error={(tagsError ?? newsError)!}
          mutate={handleErrorRetry}
        />
      }
    </Page>
  )
}

export default NewsListPage
