import { useCallback, useEffect } from 'preact/hooks'
import { App as CapacitorApp } from '@capacitor/app'
import type { BackButtonEvent } from '@ionic/core'
import { useIonRouter } from '@ionic/react'

/**
 * Native platform handlers
 */
const NativeHandlers: preact.FunctionComponent = () => {
  const { canGoBack } = useIonRouter()

  /**
   * Handle back button
   * Event fired only on Android platform (iOS has no harware back button)
   * @link https://ionicframework.com/docs/developing/hardware-back-button#exiting-the-app
   */
  const handleIonBackButton = useCallback((ev: BackButtonEvent) =>
    ev.detail.register(-1, () => {
      if (!canGoBack()) {
        CapacitorApp.exitApp()
      }
    }),
    [canGoBack]
  )

  useEffect(() => {
    document.addEventListener('ionBackButton', handleIonBackButton)

    return () => document.removeEventListener('ionBackButton', handleIonBackButton)
  }, [])

  return null
}

export default NativeHandlers
