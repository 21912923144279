import { render } from 'preact'

import App from './components/App'

import './utils/polyfills'

render(
  <App env={import.meta.env} />,
  document.getElementById('app')!
)
