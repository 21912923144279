import { useRouteMatch } from 'react-router'
import { SWRConfig } from 'swr'

import type { OrganisationPageProps } from '../../contexts/organisation'
import type { TAirQualityStationSchema } from '../../models/api'

import { useItems } from '../../data/api'
import { useApiConfiguration } from '../../data/syngeos/ApiContext'
import useIonViewVisibility from '../../hooks/useIonViewVisibility'

import Page from '../../components/Page'
import AirQualityStation from '../../components/AirQualityStation'
import { ReactComponent as AirQualityIcon } from '../../images/pages/air-quality.svg'
import TransferErrorAlert from '../../components/TransferErrorAlert'
import NoItems from '../../components/NoItems'

/**
 * Air quality stations list page
 */
const AirQualityStationsListPage: preact.FunctionComponent = () => {
  const { params: { organisationSlug } } = useRouteMatch<OrganisationPageProps>()

  const isVisible = useIonViewVisibility()

  const {
    data: airQualityStations,
    error: airQualityStationsError,
    mutate: airQualityStationsMutate,
  isLoading: isLoadingAirQualityStations,
  } = useItems<TAirQualityStationSchema>('aq_stations', {
    filter: { organisation: { slug: organisationSlug } },
    sort: ['station_id'],
    limit: -1,
  }, isVisible)

  // Use cache provider here to overrde upper level SWRConfig option
  const { provider } = useApiConfiguration()

  return (
    <SWRConfig value={{ provider }}>
      <Page
        screenName="AirQuality"
        theme={{
          name: 'air-quality',
          title: 'Jakość powietrza',
          icon: AirQualityIcon,
        }}
        isLoading={isLoadingAirQualityStations}
      >
        {airQualityStations.map(airQualityStation =>
          <AirQualityStation
            key={airQualityStation.station_id}
            airQualityStation={airQualityStation}
          />
        )}

        {!airQualityStations.length && !isLoadingAirQualityStations &&
          <NoItems />
        }
      </Page>

      {airQualityStationsError &&
        <TransferErrorAlert
          error={airQualityStationsError}
          mutate={airQualityStationsMutate}
        />
      }
    </SWRConfig>
  )
}

export default AirQualityStationsListPage
