import { useEffect, useRef } from 'preact/hooks'
import { useRegisterSW } from 'virtual:pwa-register/preact'
import {
  type ToastButton,
  IonToast,
} from '@ionic/react'
import { informationCircle } from 'ionicons/icons'

/**
 * Note: Doesn't restart after update just after install, requires refresh
 * Test: `(await navigator.serviceWorker.getRegistration()).update()`
 *
 * @link https://vite-plugin-pwa.netlify.app/examples/preact.html
 * @link https://github.com/antfu/vite-plugin-pwa/blob/main/src/client/build/preact.ts
 *
 * @todo Skip for chrome < 68 which break in sw.js
 * @link https://bugs.chromium.org/p/chromium/issues/detail?id=832202
 * @link https://github.com/GoogleChrome/workbox/issues/1433#issuecomment-381656479
 */
const ReloadPrompt: preact.FunctionComponent<{
  /** Duration before hiding toast */
  toastDuration?: number,
  /** Update check interval */
  checkInterval?: number,
}> = ({
  toastDuration = 15 * 1e3,
  checkInterval = 60 * 60 * 1e3,
}) => {
  const registration = useRef<ServiceWorkerRegistration>()

  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    immediate: true,
    onRegisteredSW(swURL, serviceWorkerRegistration) {
      registration.current = serviceWorkerRegistration
    },
  })

  // Periodic updates
  useEffect(() => {
    if (!registration.current) {
      return
    }

    const intervalId = setInterval(
      () => registration.current?.update().catch(() => {}),
      checkInterval
    )

    return () =>
      window.clearInterval(intervalId)
  }, [registration.current, checkInterval])

  const updateButton: ToastButton = {
    text: 'Reload',
    handler: () => updateServiceWorker(true),
  }

  const closeButton: ToastButton = {
    text: 'Close',
    handler: () => {
      setOfflineReady(false)
      setNeedRefresh(false)
    },
  }

  return (
    <IonToast
      isOpen={offlineReady || needRefresh}
      message={offlineReady
        ? 'App ready to work offline'
        : 'App updated, click on reload button to restart.'
      }
      icon={informationCircle}
      duration={toastDuration}
      buttons={needRefresh
        ? [updateButton, closeButton]
        : [closeButton]
      }
    />
  )
}

export default ReloadPrompt
