import './InteractivePoint.css'

/**
 * Clean Air Interactive Point
 * Based on [joyride]{@link https://www.npmjs.com/package/react-joyride}
 */
const InteractivePoint: preact.FunctionComponent<{
  coords: [number, number],
  title: string,
  onClick: (event: MouseEvent) => void,
}> = ({
  coords: [top, left],
  title,
  onClick,
}) =>
  <button
    className="m1-interactive-point"
    type="button"
    title={title}
    style={{ top: `${top}%`, left: `${left}%` }}
    onClick={onClick}
  >
    <span className="m1-interactive-point__inner"></span>
    <span className="m1-interactive-point__outer"></span>
  </button>

export default InteractivePoint
