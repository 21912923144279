import { create } from 'zustand'
import { devtools, persist, redux, createJSONStorage } from 'zustand/middleware'
import { Capacitor } from '@capacitor/core'

import { name as appName } from '../../package.json'

import getStorage from './StateStorageFactory'

import type { IAppState, IAction } from './types'

// Initial states
import { initialState as organisation } from './organisation'
import { initialState as orgsPrefs } from './orgs-prefs'

// Reducers
import { reducer as organisationReducer } from './organisation'
import { reducer as orgsPrefsReducer } from './orgs-prefs'

/**
 * Initial state
 */
const initialState: IAppState = {
  organisation,
  orgsPrefs,
}

/**
 * Root reducer
 */
const rootReducer = (state: IAppState, action: IAction): IAppState => ({
  organisation: organisationReducer(state.organisation, action),
  orgsPrefs: orgsPrefsReducer(state.orgsPrefs, action),
})

/**
 * Store
 */
export const useStore = create(devtools(persist(redux(rootReducer, initialState), {
  /**
   * Use unique local storage name to not mix up data on development with other projects
   * Applies only on localhost and platform: web/ non-native
   */
  name: Capacitor.getPlatform() === 'web' && location.hostname === 'localhost'
    ? `${appName}/state`.replace(/\//g, '.')
    : 'state'
  ,
  storage: createJSONStorage(getStorage),
  version: 0,
})))

export { AppStateProvider } from './AppStateContext'
