/**
 * Get API URL without throwing an exception
 */
export function getApiUrl(urlString: string, baseUrl: string): URL | null {
  let url: URL

  try {
    /** @throws {TypeError} - Invalid URL */
    url = new URL(urlString, baseUrl)
  } catch (typeError) {
    return null
  }

  // Detect API URL
  return url.href.startsWith(`${baseUrl}/assets/`)
    ? url
    : null
}
