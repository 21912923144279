import {
  IonCard, IonCardContent
} from '@ionic/react'

/**
 * No items message
 */
const NoItems: preact.FunctionComponent = () =>
  <IonCard className="m1-card">
    <IonCardContent>
      {'Brak pozycji'}
    </IonCardContent>
  </IonCard>

export default NoItems
