import { useRouteMatch } from 'react-router'
import {
  IonCard,
  IonCardContent,
} from '@ionic/react'

import type { TOfficeContactSchema } from '../../models/api'
import type { OrganisationPageProps } from '../../contexts/organisation'

import { useOrganisation } from '../../contexts/organisation'
import { useItems } from '../../data/api'
import { Preset } from '../../constants/asset'
import useIonViewVisibility from '../../hooks/useIonViewVisibility'

import Page from '../../components/Page'
import Markdown from '../../components/Markdown'
import ApiImage from '../../components/ApiImage'
import TransferErrorAlert from '../../components/TransferErrorAlert'
import OfficeContact from '../../components/OfficeContact'

import { ReactComponent as AboutCityIcon } from '../../images/pages/about-city.svg'

import './AboutCityPage.css'

/**
 * About city pave
 */
const AboutCityPage: preact.FunctionComponent = () => {
  const { params: { organisationSlug } } = useRouteMatch<OrganisationPageProps>()

  // Note: Organisation is nullified when navigating outside org scope
  const organisation = useOrganisation()

  const isVisble = useIonViewVisibility()

  const {
    data: officeContacts,
    error: officeConcatsError,
    isLoading: isLoadingOfficeContacts,
    mutate: officeContactsMutate,
  } = useItems<TOfficeContactSchema>('office_contacts', {
    filter: { organisation: { slug: organisationSlug } },
  }, isVisble)

  return (
    <Page
      screenName="About"
      theme={{
        name: 'about-city',
        title: 'O mieście',
        icon: AboutCityIcon,
      }}
      isLoading={isLoadingOfficeContacts}
    >
      {organisation &&
        <IonCard className="m1-about-card">
          {organisation.logo &&
            <ApiImage
              asset={organisation.logo}
              preset={Preset.OrganisationLogo}
              wrapperClassName="m1-coa ion-margin-bottom"
              className="m1-coa__element"
              alt={organisation.name}
            />
          }

          <IonCardContent className="ion-no-padding">
            <Markdown markdown={organisation.description} />

            {organisation.website &&
              <a
                href={organisation.website}
                target="_blank"
                rel="noopener"
              >
                {organisation.website}
              </a>
            }
          </IonCardContent>
        </IonCard>
      }

      {officeContacts.map(officeContact =>
        <OfficeContact
          key={officeContact.id}
          item={officeContact}
        />
      )}

      {/** Error dialog */}
      {officeConcatsError &&
        <TransferErrorAlert
          error={officeConcatsError}
          mutate={officeContactsMutate}
        />
      }
    </Page>
  )
}

export default AboutCityPage
