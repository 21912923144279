import { useState } from 'preact/hooks'
import {
  useIonViewWillEnter,
  useIonViewDidLeave,
} from '@ionic/react'

/**
 * True when page will enter, false when page did leave.
 * Requires IonPage component
 * @see https://ionicframework.com/docs/react/lifecycle
 */
const useIonViewVisibility = (): boolean => {
  const [ isVisible, setIsVisible ] = useState<boolean>(false)

  // Lifecycle hooks
  useIonViewWillEnter(() => setIsVisible(true))
  useIonViewDidLeave(() => setIsVisible(false))

  return isVisible
}

export default useIonViewVisibility
