import type { SWRConfiguration, KeyedMutator } from 'swr'
import useSWR from 'swr'

import type {
  IGlobalQueryParams,
  ManyItems,
} from './types'
import { useApi } from './ApiContext'
import { TransferError } from '../../utils/Error'

const fallbackData = null

/**
 * Generic item from collection
 * To query single item by filter instead of primary key
 */
export default function useItemQuery<Schema extends object> (
  resource: string,
  query: IGlobalQueryParams<Schema>,
  shouldFetch: boolean = true,
  customConfig?: SWRConfiguration<ManyItems<Schema>, TransferError>
): {
  data: Schema | null,
  error: TransferError | undefined,
  isLoading: boolean,
  mutate: KeyedMutator<ManyItems<Schema>>,
} {
  const path = `items/${resource}`
  const { config } = useApi()

  query = {
    ...query,
    limit: 1,
  }

  const {
    data,
    error,
    mutate,
  } = useSWR<ManyItems<Schema>, TransferError>(shouldFetch ? { path, query } : null, {
    fallbackData: undefined,
    ...config,
    ...customConfig,
  })

  // TODO: Return 404 error when results are empty
  return {
    data: data && data.data.length ? data.data[0] : fallbackData,
    error: shouldFetch && !data ? error : undefined,
    isLoading: shouldFetch && !data && !error,
    mutate,
  }
}
